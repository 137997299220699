import React, { useEffect, useState } from 'react';
import BuyNowHeader from './BuyNowHeader';
import BuyNowContent from './BuyNowContent';
import { useNavigate } from 'react-router-dom';

function BuyNow() {

    const [sessiondata, setSessiondata] = useState({ email: null, role: null });
    const navigate = useNavigate();
  
    useEffect(() => { 
      const userData = JSON.parse(sessionStorage.getItem('user'));
      if (userData && userData.email && (userData.role === 'user')) {
        setSessiondata(userData);
      } else {
        console.log('Invalid session or role, redirecting to login');
        navigate('/');
      }
    }, [navigate]);
    return (
        <div>
            <div className=''>
                <BuyNowHeader/>
            </div>
            <div>
                <BuyNowContent/>
            </div>
        </div>
    );
}

export default BuyNow;
