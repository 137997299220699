import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import SoldGraph from './SoldGraph';

// Utility function to format numbers
const formatNumber = (num) => {
    if (num >= 10000000) {
        return (num / 10000000).toFixed(1).replace(/\.0$/, '') + 'Cr'; // For crores
    }
    if (num >= 100000) {
        return (num / 100000).toFixed(1).replace(/\.0$/, '') + 'L'; // For lakhs
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k'; // For thousands
    }
    return num.toString(); // For numbers below 1000
};

function SoldDishes() {
    const [data, setData] = useState([]); // State to hold the fetched data
    const [visibleItems, setVisibleItems] = useState(6);

    useEffect(() => {
        // Fetch data from the backend
        const fetchData = async () => {
            try {
                const response = await fetch('https://backend.bkabab.com/showaddtocart'); // Adjust the URL as needed
                const result = await response.json();
                setData(result); // Update the state with fetched data
            } catch (error) {
                console.error('Error fetching dishes:', error);
            }
        };
        fetchData();
        const intervalId = setInterval(() => {
            fetchData();
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const handleViewMore = () => {
        setVisibleItems((prev) => prev + 6);
    };

    return (
        <div className='dashbord-favourite-items-main'>
            <div className='dashbord-favourite-item-tital-main'>
                <p>Most Sold Items</p>
            </div>
            <Row>
                {data.slice(0, visibleItems).map(item => (
                    <Col md={6} lg={4} className='col-card-faveriout-items' key={item._id}>
                        <div className='main-card-favrioute-items-dashbord'>
                            <img src={`https://backend.bkabab.com/foodimage/${item.dishimage}`} className='image-fluid faveriouts-items-card-image' />
                            <div className='dashbord-card-tital-likes-graph'>
                                <div className='dashbord-card-tital-cart'>
                                    <p className='faveriouts-item-tital'>{item.dishname}</p>
                                    <div className='card-faveriouts-items-cart'>
                                        <i className="las la-cart-arrow-down"></i>
                                        <p>{formatNumber(item.addToCartCount)}</p>
                                    </div>
                                </div>
                                <div>
                                    <SoldGraph />
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
            {visibleItems < data.length && (
                <div className='view-more-faverioute-main'>
                    <div onClick={handleViewMore} className='text-center view-more-btn-for-faveriout-cards'>
                        <p>View More</p><i className="las la-angle-down"></i>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SoldDishes;
