import React, { useState, useEffect } from 'react';
import {  Container, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderMenu from '../../Components/Header/HeaderMenu';

function BuyNowHeader() {
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            if (scrollTop > 50) {
                setIsHeaderFixed(true);
            } else {
                setIsHeaderFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className={`header-navbar ${isHeaderFixed ? 'fixed' : ''}`}>
            <Container fluid className='header-container-main'>
                <Navbar className='buynow-header-nav'>
                    <div className='buynow-mainheader-div'>
                        <Link to="/" className='header-logo'>
                            <img src='./kabab-house-Left-Right.jpg' className='image-fluid logo-image-header-main' alt="Bkabab Logo" />
                        </Link>
                        <div className='Header-links-1'>
                            <HeaderMenu />
                        </div>
                    </div>
                </Navbar>
                <div className='Header-links-2'>
                    <HeaderMenu />
                </div>
            </Container>

        </div>
    )
}

export default BuyNowHeader
