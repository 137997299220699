import React from 'react';
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap';


function TopFooter() {
    return (
        <div className='top-footermain'>
            <Container fluid className='session-3-container-main'>
                <Row className='row-footertop'>
                    <Col xs={12} lg={4} xl={2}  className='coloum-main-image-logo-footer'>
                        <img src='./img/BkababLogo.jpg' className=' image-logo-footer'/>
                    </Col>
                    <Col xs={12} sm={6} lg={4} xl={3}  className='coloum-footer-top-branches'>
                        <div className='main-branch-top-footer'>
                            <h3>First Branch</h3>
                            <div className='branches-top-Footer'>
                            <i class="las la-map-marker-alt"></i>
                            <p>KABAB HOUSE HOORA, Exhibition Road, Opposite Gosi Complex.</p>
                            </div>
                            <div className='branches-top-Footer-phone'>
                            <i className="las la-phone"></i>
                            <p>+973 16161000</p>
                            </div>
                            <div className='branches-top-Footer-email'>
                            <i class="lab la-whatsapp"></i>
                            <p>3594 0444</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} lg={4} xl={3}className='coloum-footer-top-branches'>
                        <div  className='main-branch-top-footer'>
                            <h3>Second Branch</h3>
                            <div className='branches-top-Footer'>
                                <i class="las la-map-marker-alt"></i>
                                <p>KABAB HOUSE RIFFA, Building 463, Road 83, Block 911, East Riffa.</p>
                            </div>
                            <div className='branches-top-Footer-phone'>
                            <i className="las la-phone"></i>
                            <p>+973 16161000</p>
                            </div>
                            <div className='branches-top-Footer-email'>
                            <i class="lab la-whatsapp"></i>
                            <p>3594 0555</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} xl={2} className='coloum-footer-top-time'>
                        <div>
                            <h3>Timing</h3>
                            <div className='time-footer-open'>
                                <p>All Week Open</p>
                                <p className='time'>12:00 PM </p>
                            </div>
                            <br/>
                            <div className='time-footer-close'> 
                                <p>WeekDays Closed</p>
                                <p className='time'>12:00 AM</p>
                            </div>
                            <br/>
                            <div className='time-footer-close'> 
                                <p>Weekends Closed</p>
                                <p className='time'>01:00 AM</p>
                            </div>
                        </div>

                    </Col>
                    <Col xs={12} sm={6} xl={2} className='coloum-footer-top-social'>
                        <div>
                            <h3>GET SOCIAL WITH US</h3>
                            <div className='footerlinks-social'>
                                <Link to="" className="custom-link-footer"><i className="lab la-facebook"></i></Link>
                                <Link to="" className="custom-link-footer"><i className="lab la-youtube"></i></Link>
                                <Link to="" className="custom-link-footer"><i className="lab la-instagram"></i></Link>
                                <Link to="" className="custom-link-footer"><i className="lar la-envelope"></i></Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TopFooter
