import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row,Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function FormAddnewadminuser() {
    const location = useLocation();
    const adminData = location.state?.adminData || {};
    const [showPassword, setShowPassword] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [handle, setHandle] = useState({
        firstName: adminData.firstName || '',
        lastName: adminData.lastName || '',
        email: adminData.email || '',
        phoneno: adminData.phoneno || '',
        password: adminData.password || '',
        role: adminData.role || '',
        dateofbirth: adminData.dateofbirth || ''
    });
    const [error, setError] = useState({});
    const [isEditMode, setIsEditMode] = useState(!!adminData._id);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleInput = (e) => {
        const { name, value } = e.target;
        setHandle({ ...handle, [name]: value });

        // Clear the error for the input field being changed
        if (error[name]) {
            setError({ ...error, [name]: '' });
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0] || null;
        setSelectedImage(file);

        if (file) {
            // Validate image file type
            const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!validTypes.includes(file.type)) {
                setError({ ...error, image: 'Invalid file type. Only JPEG and PNG are allowed.' });
                setSelectedImage(null);
                setImagePreviewUrl('');
                return;
            }

            // Validate image file size (e.g., max 5MB)
            if (file.size > 5 * 1024 * 1024) {
                setError({ ...error, image: 'File size exceeds 5MB.' });
                setSelectedImage(null);
                setImagePreviewUrl('');
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);

            // Clear the error for the image field
            if (error.image) {
                setError({ ...error, image: '' });
            }
        } else {
            setImagePreviewUrl('');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validateInputs = () => {
        let validationErrors = {};

        // Basic required field validations
        if (!handle.firstName) validationErrors.firstName = 'First name is required.';
        if (!handle.lastName) validationErrors.lastName = 'Last name is required.';
        if (!handle.email) {
            validationErrors.email = 'Email is required.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(handle.email)) {
            validationErrors.email = 'Invalid email address.';
        }
        if (!handle.phoneno) {
            validationErrors.phoneno = 'Phone number is required.';
        } else if (handle.phoneno.includes(' ')) {
            validationErrors.phoneno = 'Phone number cannot contain spaces.';
        } else if (!/^\+?\d+$/.test(handle.phoneno)) {
            validationErrors.phoneno = 'Phone number must contain only Number .';
        } else if (handle.phoneno.length < 10) {
            validationErrors.phoneno = 'Phone number must be at least 10 digits.';
        } else if (handle.phoneno.length > 12) {
            validationErrors.phoneno = 'Phone number must not exceed 12 digits.';
        }
        if (!handle.password) {
            validationErrors.password = 'Password is required.';
        } else if (handle.password.length < 8) {
            validationErrors.password = 'Password must be at least 8 characters long.';
        }
        if (!handle.role) validationErrors.role = 'Role is required.';
        if (!handle.dateofbirth) validationErrors.dateofbirth = 'Date of birth is required.';
        if (!selectedImage) validationErrors.image = 'Image is required';

        return validationErrors;
    };

    const handleFormData = async (e) => {
        e.preventDefault();
        setIsLoading(true);  // Set loading state

        const validationErrors = validateInputs();
        if (Object.keys(validationErrors).length > 0) {
            setError(validationErrors);
            return;
        }

        try {
            const formData = new FormData();
            formData.append('firstName', handle.firstName);
            formData.append('lastName', handle.lastName);
            formData.append('email', handle.email);
            formData.append('phoneno', handle.phoneno);
            formData.append('password', handle.password);
            formData.append('role', handle.role);
            formData.append('dateofbirth', handle.dateofbirth);

            if (selectedImage) {
                formData.append('image', selectedImage);
            }

            const url = isEditMode ? `https://backend.bkabab.com/admin/${adminData._id}` : 'https://backend.bkabab.com/addadmin';
            const method = isEditMode ? 'PUT' : 'POST';

            const response = await fetch(url, {
                method,
                body: formData,
            });

            if (!response.ok) {
                const errorText = await response.text();
                if (response.status === 400 && errorText === 'Email already exists') {
                    setError({ email: 'Email already exists' });
                    
                    return;
                }
                throw new Error(`Failed to ${isEditMode ? 'update' : 'create'} user: ${response.statusText}`);
            }

            toast.success(`Admin ${isEditMode ? 'updated' : 'added'} successfully`, {
                position: "top-right",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            navigate('/admininfo');
            setHandle({
                firstName: '',
                lastName: '',
                email: '',
                phoneno: '',
                password: '',
                role: '',
                dateofbirth: ''
            });
            setSelectedImage(null);
            setImagePreviewUrl('');
        } catch (error) {
            console.error(`Error ${isEditMode ? 'updating' : 'creating'} admin:`, error.message);
            setError({ form: `Error ${isEditMode ? 'updating' : 'creating'} admin: ` + error.message });
        }finally {
            setIsLoading(false);  // Reset loading state
        }
    };

    useEffect(() => {
        if (adminData.image && !selectedImage) {
            setImagePreviewUrl(`https://backend.bkabab.com/foodimage/${adminData.image}`);
        }
    }, [adminData, selectedImage]);

    return (
        <div className='one-form-addnew-adminuser'>
            <div className='second-form-addnew-adminuser'>
                <Row className='main-rowadmin-form'>
                    <Col xs={11} md={10} xl={8}>
                        <Form>
                            {error.form && <p className='error-form'>{error.form}</p>}
                            <Form.Group className='input-form-addadminuser' controlId="formFirstName">
                                <Form.Label className='adddish-input-lable'>First Name</Form.Label>
                                <Form.Control
                                    name='firstName'
                                    type="text"
                                    placeholder="F-name *"
                                    value={handle.firstName}
                                    onChange={handleInput}
                                    isInvalid={!!error.firstName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.firstName}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='input-form-addadminuser' controlId="formLastName">
                                <Form.Label className='adddish-input-lable'>Last Name</Form.Label>
                                <Form.Control
                                    name='lastName'
                                    type="text"
                                    placeholder="L-name *"
                                    value={handle.lastName}
                                    onChange={handleInput}
                                    isInvalid={!!error.lastName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.lastName}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='input-form-addadminuser' controlId="formPhoneNo">
                                <Form.Label className='adddish-input-lable'>Phone No.</Form.Label>
                                <Form.Control
                                    name='phoneno'
                                    type="number"
                                    placeholder="Phone No. *"
                                    value={handle.phoneno}
                                    onChange={handleInput}
                                    isInvalid={!!error.phoneno}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.phoneno}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='input-form-addadminuser' controlId="formEmail">
                                <Form.Label className='adddish-input-lable'>Email address</Form.Label>
                                <Form.Control
                                    name='email'
                                    type="email"
                                    placeholder="E-mail *"
                                    value={handle.email}
                                    onChange={handleInput}
                                    isInvalid={!!error.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Label className='adddish-input-lable'>Select Role & Date of Birth</Form.Label>
                            <div className='admin-form-role-date'>
                                <Form.Select
                                    name='role'
                                    className='input-form-addadminuser-role'
                                    aria-label="Default select"
                                    value={handle.role}
                                    onChange={handleInput}
                                    isInvalid={!!error.role}
                                >
                                    <option value="">Role *</option>
                                    <option value="Super-admin">Super-Admin</option>
                                    <option value="Sab-admin">Sab-Admin</option>
                                </Form.Select>
                                <Form.Group className='input-form-addadminuser-date' controlId="formDateOfBirth">
                                    <Form.Control
                                        name='dateofbirth'
                                        type="date"
                                        placeholder="Date of Birth *"
                                        value={handle.dateofbirth}
                                        onChange={handleInput}
                                        isInvalid={!!error.dateofbirth}
                                    />

                                </Form.Group>
                            </div>
                            <Form.Group className='input-form-addadminuser' controlId="formPassword">
                                <Form.Label className='adddish-input-lable'>Password</Form.Label>
                                <div className='password-input-container'>
                                    <Form.Control
                                        name='password'
                                        type={showPassword ? "text" : "password"}
                                        value={handle.password}
                                        onChange={handleInput}
                                        isInvalid={!!error.password}
                                    />
                                    <Form.Control.Feedback className='form-adminpassword' type="invalid">
                                        {error.password}
                                    </Form.Control.Feedback>
                                    <span className='password-toggle-icon' onClick={togglePasswordVisibility}>
                                        {showPassword ?'👁️' : '🙈'}
                                    </span>
                                </div>
                            </Form.Group>
                            <Form.Group className='input-form-addadminuser' controlId="formImage">
                                <Form.Label className='adddish-input-lable'>Profile Picture</Form.Label>
                                <Form.Control
                                    name='image'
                                    type="file"
                                    onChange={handleImageChange}
                                    isInvalid={!!error.image}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.image}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {imagePreviewUrl && (
                                <div className='previewImage'>
                                    <img className='previewImage' src={imagePreviewUrl} alt='Preview' />
                                </div>
                            )}
                            <Button
                                className='form-submit-link-adddishes'
                                variant="primary"
                                type="submit"
                                onClick={handleFormData}
                            >{isLoading ? <Spinner animation="border" size="sm" /> : (isEditMode ? 'Update Admin' : 'Add Admin')}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default FormAddnewadminuser;
