import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SearchBarAdminPenal from '../../../SearchBarAdminPenal/SearchBarAdminPenal';

function OrderSearchbar() {
  return (
    <div>
      <Row className='main-row-search-bar'>
        <Col md={6} lg={6} xl={6}><SearchBarAdminPenal/></Col>
        <Col md={6} lg={4} className='main-coloum-search-bar-btns'>
        <Link to="/history" className='links-order-component'>Order History</Link>
        <Link to="/alldishes" className='links-order-component'>Manage Dishes</Link>
        </Col>
      </Row>
    </div>
  )
}

export default OrderSearchbar
