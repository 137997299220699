import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function Card2() {
  const [totalRevenue, setTotalRevenue] = useState(0);

  // Function to calculate the percentage dynamically
  const calculatePercentage = (revenue) => {
    if (revenue < 1000) {
      return (revenue / 1000) * 100; // Percentage out of 1000
    } else if (revenue < 10000) {
      return (revenue / 10000) * 100; // Percentage out of 10000
    } else if (revenue < 100000) {
      return (revenue / 100000) * 100; // Percentage out of 100000
    }else if (revenue < 1000000) {
      return (revenue / 1000000) * 100; // Percentage out of 10000
    }else if (revenue < 10000000) {
      return (revenue / 10000000) * 100; // Percentage out of 10000
    } else if (revenue < 100000000) {
      return (revenue / 100000000) * 100; // Percentage out of 10000
    } else if (revenue < 1000000000) {
      return (revenue / 1000000000) * 100; // Percentage out of 100000
    }else if (revenue < 10000000000) {
      return (revenue / 10000000000) * 100; // Percentage out of 10000
    }else if (revenue < 100000000000) {
      return (revenue / 100000000000) * 100; // Percentage out of 10000
    }
    // You can continue adding more ranges as needed
    return 100;
  };

  useEffect(() => {
    const fetchCompletedOrdersData = async () => {
      try {
        const response = await fetch('https://backend.bkabab.com/orders/revenue/completed');
        const data = await response.json();
        setTotalRevenue(data.totalRevenue);
      } catch (error) {
        console.error('Error fetching completed orders data:', error);
      }
    };

    fetchCompletedOrdersData();

    // Set up interval to fetch data every 1 seconds
    const intervalId = setInterval(() => {
      fetchCompletedOrdersData();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Calculate the percentage for the circular progress bar
  const percentage = calculatePercentage(totalRevenue);

  // Utility function to format the number with appropriate suffix
  const formatNumber = (num) => {
    if (num === 0) return '0';
    const suffixes = ['', 'k', 'M', 'B', 'T', 'P', 'E']; // Extendable for larger numbers
    const tier = Math.floor(Math.log10(Math.abs(num)) / 3);
    const suffix = suffixes[tier];
    const scale = Math.pow(10, tier * 3);

    // Scale the number and add the suffix
    const scaledNumber = num / scale;

    // Ensure the number is formatted to one decimal place if it's a fraction
    return scaledNumber.toFixed(1) + suffix;
  };

  return (
    <div className='card-main-body-dashbord'>
      <Row>
        <Col>
          <p className='adminpenal-card-tital'>Total Revenue</p>
          <p className='adminpenal-card-value'>{formatNumber(totalRevenue)}</p>
        </Col>
        <Col className='progressCol-dashbord-cards'>
          <div className='progressContainer-main-container-dashbord'>
            <CircularProgressbar
              value={percentage}
              styles={buildStyles({
                pathColor: `#006e6b`,
              })}
            />
            <i className="las la-dollar-sign icons-cards-dashbord"></i>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Card2;
