import React from 'react'
import { useKababContext } from '../../../../KababContext';
import {Col, Container, Row} from 'react-bootstrap'
import SearchBarBtns from './SearchBarBtns/SearchBarBtns';
import OrderHistoryList from './OrderHistoryList/OrderHistoryList';

function HistoryContent() {
  const { sidebarOpen } = useKababContext();
  const contentStyle = sidebarOpen ? 'Side-bar-closed' : 'Side-bar-open';
  return (
    <div className={contentStyle}>
      <p className='main-tital-adminpenal'>
        Orders History
      </p>
      <Container fluid>
        <Row className='main-row-order-history'>
          <Col md={6} ><SearchBarBtns/></Col>
        </Row>
        <Row>
        <Col> <OrderHistoryList/></Col>
        </Row>
          
          </Container>
    </div>
  )
}

export default HistoryContent
