import React from 'react'
import Header from '../../Components/Header/Header'

function KababHooramenu() {
    return (
        <div>
            <div>
                <Header />
            </div>
            <div >
            <h2 className='main-div-iframepdfshow'>KABAB HOUSE HOORA MENU</h2>
            <iframe  src="HooraMenu.pdf" className='pdf-menu' title="PDF Viewer" />
            </div>
        </div>
    )
}

export default KababHooramenu

