import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function TimeComponent() {
    return (
        <div className='session2-time'>
            <Row className='time-row'>
                <Col className='time-col'>
                    <div className="clock">
                        <div className="center"></div>
                        <div className="hour"></div>
                        <div className="minute"></div>
                        <div className="second"></div>
                    </div>
                </Col>
                <Col xs={8} className='time-col'>
                    <div className='timingtable-main'>
                        <p>Opening Time 12:00 PM</p>
                        <p>Closing Time 01:00 AM</p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default TimeComponent;
