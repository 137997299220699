import React from 'react'
import { useKababContext } from '../../../../KababContext';
import GraphCard from './Graphs/GraphCard';
import { Row, Col, Container } from 'react-bootstrap';
import Card1 from './CardCircleGraph/DashBordCard1/Card1';
import Card2 from './CardCircleGraph/DashBordCard2/Card2';
import Card3 from './CardCircleGraph/DashBordCard3/Card3';
import Card4 from './CardCircleGraph/DashBordCard4/Card4';
import { Link } from 'react-router-dom';
import SoldDishes from './SoldDishes/SoldDishes';
import GraphCardComplete from './Graphs/GraphCardComplete';


function DashbordContent() {
  const { sidebarOpen } = useKababContext();
  const contentStyle = sidebarOpen ? 'Side-bar-closed' : 'Side-bar-open';
  return (
    <div className={contentStyle}>
      <p className='main-tital-adminpenal'>
        Dashboord
      </p>
      <Container fluid className='main-container-dashbord-card-graphs'>
        <div className='main-dashbord-cards'>
          <Row>
            <Col md={6} xxs={12} className='main-card-col-dashbord'>
              <Card4 />
            </Col>
            <Col md={6} xxs={12} className='main-card-col-dashbord'>
              <Card2 />
            </Col>
            <Col md={6} xxs={12} className='main-card-col-dashbord'>
              <Card3 />
            </Col>
            <Col md={6} xxs={12} className='main-card-col-dashbord'>
              <Card1 />
            </Col>
          </Row>
          
          <Row className='mobile-screen-graphs'>
            <Col md={12} >
              <div className='main-graps-dashbords1'>
                <div className='main-dashbord-graphs-first'>
                  <GraphCard />
                </div>
                <div className='main-dashbord-graphs-second'>
                  <GraphCardComplete/>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='main-btns-dashbord'>
            <Col md={12} >
              <div className='main-links-dashbord'>
                <Link className='link1-main-dashbord' to="/orders">Orders</Link>
                <Link className='link1-main-dashbord' to="/history">Orders &nbsp;History</Link>
                <Link className='link1-main-dashbord' to="/alldishes">Dishes</Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} >
              <SoldDishes />
            </Col>
          </Row>
        </div>
        <div className='main-graps-dashbords2'>
          <div className='main-dashbord-graphs-first'>
            <GraphCard />
          </div>
          <div className='main-dashbord-graphs-second'>
            <GraphCardComplete />
          </div>
        </div>
      </Container>

    </div>
  )
}

export default DashbordContent
