import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Form, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

function LoginModel({ show, handleClose, handleShowSignupModel }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add this state
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setValidated(true);
    const form = e.currentTarget;

    // Validate email and password before submitting
    if (!email || !password) {
      toast.error('Please fill in all fields.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
      return;
    }

    if (password.length < 8) {
      toast.error('Password must be at least 8 characters long.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
      return;
    }

    if (form.checkValidity() === false) {
      e.stopPropagation();
      return;
    }

    setIsLoading(true); // Disable button and show loading

    try {
      const response = await axios.post('https://backend.bkabab.com/login', { email, password });
      const { role, data } = response.data;

      const userData = {
        email: data.email,
        role,
        name: data.name,
        phoneno: data.phoneno,
        firstName: data.firstName,
        lastName: data.lastName,
        image: data.image,
        _id: data._id
      };
      sessionStorage.setItem('user', JSON.stringify(userData));

      if (role === 'Super-admin' || role === 'Sab-admin') {
        navigate('/dashboard');
        toast.success(role + ' Login Successfully', {
          position: "top-right",
          autoClose: 600,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      } else if (role === 'user') {
        navigate('/');
        toast.success('User Login Successfully', {
          position: "top-right",
          autoClose: 600,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      }

      setEmail('');
      setPassword('');
      handleClose();
    } catch (error) {
      toast.error('Invalid email or password', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    } finally {
      setIsLoading(false); // Re-enable button after login attempt
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title className='model-main'>
          <img src='BkababLogo.jpg' width={70} alt='Bahrain Kebabs Logo' />
          <p className='mode-tital'>Login in Kabab House</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleLogin}>
          <Form.Group controlId="inputEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="inputPassword" className="mt-3">
            <Form.Label>Password</Form.Label>
            <div className='password-input-container'>
              <Form.Control
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span className='password-toggle-icon-login' onClick={togglePasswordVisibility}>
                {showPassword ? '👁️' : '🙈'}
              </span>
            </div>
          </Form.Group>
          <div className='login-btn-main-div-model mt-3'>
            <Button type="submit" className='login-model-main' disabled={isLoading}>
              {isLoading ? <Spinner animation="border" size="sm" /> : 'Login'}
            </Button>
            <div className='mt-3'>
              <p className='text-for-register-model-login'>
                Do not have an account? <Link onClick={handleShowSignupModel} className='register-login-model-link'>Register now!</Link>
              </p>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className='models-footer-main'>
          <div className='copy-right-text-bottom-footer'>
            <p>Copyright © 2024, All Right Reserved. Powered By </p> &nbsp; &nbsp;<Link className='bottom-footer-links'> SGB WORLD </Link>&nbsp;&nbsp;
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default LoginModel;
