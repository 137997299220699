import React from 'react';
import { Line } from 'react-chartjs-2';

function SabCategoryCardGraph() {
  const data = {
    labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'], 
    datasets: [
      {
        label: 'Sales', 
        data: [0, 8, 15, 12, 20, 18, 30], 
        fill: false,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        pointRadius: 0, // Remove the data points
        pointHoverRadius: 0, // Remove the hover effect on data points
      },
    ],
  };



  const options = {
    scales: {
      x: {
        display: false, 
      },
      y: {
        display: false, 
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false, 
      },
      arrowPlugin: {}
    },
  };

  return (
    <div className='main-graph-sabcategory'>
      <Line data={data} options={options} />
    </div>
  );
}

export default SabCategoryCardGraph;
