import React from 'react'
import { Link } from 'react-router-dom'

function BottemFooter() {
  return (
    <div className='bottom-footer'>
      <div className='copy-right-text-bottom-footer'>
      <p>Copyright © 2024, All Right Reserved. Powered By </p> &nbsp; &nbsp;<Link  className='bottom-footer-links'> SGB WORLD </Link>
      </div>
    </div>
  )
}

export default BottemFooter
