import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function OrderCard1() {
    const [totalOrder, setTotalOrder] = useState(0);

    // Function to calculate the percentage dynamically
    const calculatePercentage = (orders) => {
        if (orders < 100) {
            return (orders / 100) * 100; // Percentage out of 100
        } else if (orders < 1000) {
            return (orders / 1000) * 100; // Percentage out of 1000
        } else if (orders < 10000) {
            return (orders / 10000) * 100; // Percentage out of 10000
        } else if (orders < 100000) {
            return (orders / 100000) * 100; // Percentage out of 10000
        } else if (orders < 1000000) {
            return (orders / 1000000) * 100; // Percentage out of 10000
        }
        // Cap at 100% for anything beyond 10000
        return 100;
    };

    useEffect(() => {
        const fetchTotalItems = async () => {
            try {
                const response = await fetch('https://backend.bkabab.com/totalorders');
                const data = await response.json();
                setTotalOrder(data.totalOrder);  // Updated to match the backend response key
            } catch (error) {
                console.error('Error fetching total orders:', error);
            }
        };

        // Initial fetch
        fetchTotalItems();
        // Set up interval to fetch data every 2 seconds
        const intervalId = setInterval(() => {
            fetchTotalItems();
        }, 1000); // Updated interval to 2000ms
        return () => clearInterval(intervalId);
    }, []);

    // Calculate the percentage for the circular progress bar
    const percentage = calculatePercentage(totalOrder);

    return (
        <div className='main-order-cards'>
            <Row className='adminpenal-order-row'>
                <Col xs={6} lg={6} xl={12} xxl={6}>
                    <div>
                        <p className='adminpenal-card-tital'>Total Orders</p>
                        <p className='adminpenal-card-value'>{totalOrder}</p>
                    </div>
                </Col>
                <Col xs={6} lg={6} xl={12} xxl={6} className='col-order-card'>
                    <div className='progressContainer-main-container-dashbord'>
                        <CircularProgressbar
                            value={percentage}
                            styles={buildStyles({
                                pathColor: `#006e6b`,
                            })}
                        />
                        <i className="las la-clipboard icons-cards-dashbord"></i>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default OrderCard1;
