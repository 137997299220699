import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row ,Spinner} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import ModelAddCategroy from './ModelAddCategroy';
import { toast } from 'react-toastify';

function AddDishForm() {
    const location = useLocation();
    const cardData = location.state?.cardData || {};
    const [data, setData] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [handle, setHandle] = useState({
        dishname: cardData.dishname || '',
        price: cardData.price || '',
        category: cardData.category || '',
        detail: cardData.detail || ''
    });
    const [showPickupModal, setShowPickupModal] = useState(false);
    const [error, setError] = useState({});
    const [isEditMode, setIsEditMode] = useState(!!cardData._id);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setHandle({ ...handle, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
        }
    };

    const handleFormData = async (e) => {
        e.preventDefault();
        setIsLoading(true);  // Set loading state

        // Validate form fields
        const newError = {};
        if (!handle.dishname) newError.dishname = 'Dish name is required';
        if (!handle.price) newError.price = 'Price is required';
        if (!handle.category) newError.category = 'Category is required';
        if (!handle.detail) {newError.detail = 'Description is required';}
        else if( (handle.detail.length > 25) ){
            newError.detail = 'Description must be 25 characters or less';
        }
        if (!selectedImage && !isEditMode) newError.dishimage = 'Image is required';
        if (Object.keys(newError).length > 0) {
            setError(newError);
            return;
        }
        setError({});

        const formData = new FormData();
        formData.append('dishname', handle.dishname);
        formData.append('price', handle.price);
        formData.append('category', handle.category);
        formData.append('detail', handle.detail);
        if (selectedImage) {
            formData.append('dishimage', selectedImage);
        }

        try {
            let response;
            if (isEditMode) {
                response = await fetch(`https://backend.bkabab.com/item/${cardData._id}`, {
                    method: 'PUT', 
                    body: formData,
                });
            } else {
                response = await fetch('https://backend.bkabab.com/item', {
                    method: 'POST',
                    body: formData,
                });
            }
            if (response.ok) {
                toast.success(isEditMode ? 'Dish updated successfully!' : 'Dish created successfully!', {
                    position: "top-right",
                    autoClose: 500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate('/alldishes');
                setHandle({
                    dishname: '',
                    price: '',
                    category: '',
                    detail: ''
                });
                setSelectedImage(null);
            } else {
                const errorMessage = await response.text();
                alert('Error: ' + errorMessage);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Failed to submit form: ' + error.message);
        }finally {
            setIsLoading(false);  // Reset loading state
        }
    };

    const fetchApi = async () => {
        try {
            const response = await fetch("https://backend.bkabab.com/categories");
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            const value = await response.json();
            setData(value);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchApi();
        const intervalId = setInterval(() => {
            fetchApi();
        }, 100); 
        return () => clearInterval(intervalId);
    }, []);

    const handleShowPickupModal = () => {
        setShowPickupModal(true);
    };

    const handleClosePickupModal = () => setShowPickupModal(false);

    return (
        <div className='main-adddisesform-admin-penal'>
            <p className='tital-add-dishes'>{isEditMode ? 'Edit Dish' : 'New Dish'}</p>
            <div className='main-adddishbefore-row'>
                <Row className='rowmain-form-adddishyes'>
                    <Col md={5} className='adddish-coloum-text'>
                        <p>{isEditMode ? 'Update the dish details below:' : 'Got a delicious new dish to share? Let\'s make it official and add it to the menu!'}</p>
                    </Col>
                    <Col md={6} className='main-coloum-form-adddishes'>
                        <Form onSubmit={handleFormData}>
                            <Form.Group className="forms-adddishes-input" controlId="exampleForm.ControlInput1">
                                <Form.Label className='adddish-input-lable'>Dish Name</Form.Label>
                                <Form.Control
                                    name='dishname'
                                    value={handle.dishname}
                                    onChange={handleInput}
                                    type="text"
                                    isInvalid={!!error.dishname}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.dishname}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="forms-adddishes-input" controlId="exampleForm.ControlInput1">
                                <Form.Label className='adddish-input-lable'>Price</Form.Label>
                                <Form.Control
                                    name='price'
                                    value={handle.price}
                                    onChange={handleInput}
                                    type="number"
                                    isInvalid={!!error.price}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.price}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="forms-adddishes-input" controlId="exampleForm.ControlInput1">
                                <Form.Label className='adddish-input-lable'>Category</Form.Label>
                                <div className='input-category-main'>
                                    <Form.Select
                                        className='new-categoryadd-adddishes-category'
                                        name='category'
                                        value={handle.category}
                                        onChange={handleInput}
                                        aria-label="Default select example"
                                        isInvalid={!!error.category}
                                    >
                                        <option value="">Select a category</option>
                                        {data.map((item) => (
                                            <option key={item._id} value={item.name}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                    
                                    <button type="button" onClick={handleShowPickupModal} className='new-categoryadd-adddishes'>Add Category</button>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {error.category}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="forms-adddishes-input" controlId="exampleForm.ControlInput1">
                                <Form.Label className='adddish-input-lable'>Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='detail'
                                    value={handle.detail}
                                    onChange={handleInput}
                                    isInvalid={!!error.detail}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.detail}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="forms-adddishes-input" controlId="exampleForm.ControlInput1">
                                <Form.Label className='adddish-input-lable'>Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={handleImageChange}
                                    isInvalid={!!error.dishimage}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.dishimage}
                                </Form.Control.Feedback>
                                {selectedImage && (
                                    <div style={{ marginTop: '10px' }}>
                                        <img src={URL.createObjectURL(selectedImage)} alt="Selected" className='adddishes-slected-image' />
                                    </div>
                                )}
                                {cardData.dishimage && !selectedImage && (
                                    <div style={{ marginTop: '10px' }}>
                                        <img src={`https://backend.bkabab.com/foodimage/${cardData.dishimage}`} alt={cardData.dishname} className='adddishes-slected-image' />
                                    </div>
                                )} 
                            </Form.Group>
                            <Button type="submit" className='form-submit-link-adddishes'>{isLoading ? <Spinner animation="border" size="sm" /> : (isEditMode ? 'Update Dish' : 'Add Dish')}</Button>

                        </Form>
                    </Col>
                </Row>
            </div>
            <ModelAddCategroy show={showPickupModal} handleClose={handleClosePickupModal} />
        </div>
    );
}

export default AddDishForm;
