import React from 'react'
import { useKababContext } from '../../../KababContext';

function SearchBarAdminPenal() {
  const {  handleSearch } = useKababContext();
  return (
    <div>
      <input onChange={handleSearch} placeholder='Search ...' className='input-searchbar-order'/>
    </div>
  )
}

export default SearchBarAdminPenal 