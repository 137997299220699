import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import TimeComponent from './TimeComponent'
import AddressPicup from './AddressPicup'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import DelivaryComponent from './DelivaryComponent'

function TimePickupDelivery() {
    return (
        <div className='session2-main'>
            <Container fluid className='session-3-container-main'>
                <Row>
                    <Col  className='session-2-row-main'>
                        <TimeComponent />
                    </Col>
                    <Col  className='session-2-row-main'>
                        <AddressPicup />
                    </Col>
                    <Col className='session-2-row-main' >
                        <DelivaryComponent />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TimePickupDelivery
