import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function OrderCard4() {
  const [canceledCount, setCanceledCount] = useState(0);

  // Function to calculate percentage dynamically
  const calculatePercentage = (count) => {
    if (count < 100) {
      return (count / 100) * 100; // Percentage out of 100
    } else if (count < 1000) {
      return (count / 1000) * 100; // Percentage out of 1000
    } else if (count < 10000) {
      return (count / 10000) * 100; // Percentage out of 10000
    }else if (count < 100000) {
      return (count / 100000) * 100; // Percentage out of 10000
    }else if (count < 1000000) {
      return (count / 1000000) * 100; // Percentage out of 10000
    }
    // Cap at 100% for anything beyond 10000
    return 100;
  };

  useEffect(() => {
    const fetchOrderCounts = async () => {
      try {
        const canceledResponse = await fetch('https://backend.bkabab.com/orders/count/canceled');
        const canceledData = await canceledResponse.json();
        setCanceledCount(canceledData.canceled);
      } catch (error) {
        console.error('Error fetching order counts:', error);
      }
    };

    // Initial fetch
    fetchOrderCounts();
    // Set up interval to fetch data every 2 seconds
    const intervalId = setInterval(() => {
      fetchOrderCounts();
    }, 1000); // Updated interval to 2000ms

    return () => clearInterval(intervalId);
  }, []);

  // Calculate the percentage for the circular progress bar
  const percentage = calculatePercentage(canceledCount);

  return (
    <div className='main-order-cards'>
      <Row className='adminpenal-order-row'>
        <Col xs={6} lg={6} xl={12} xxl={6}>
          <div>
            <p className='adminpenal-card-tital'>Cancelled Orders</p>
            <p className='adminpenal-card-value'>{canceledCount}</p>
          </div>
        </Col>
        <Col xs={6} lg={6} xl={12} xxl={6} className='col-order-card'>
          <div className='progressContainer-main-container-dashbord'>
            <CircularProgressbar
              value={percentage}
              styles={buildStyles({
                pathColor: `#006e6b`,
              })}
            />
            <i className="las la-times-circle icons-cards-dashbord"></i>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default OrderCard4;
