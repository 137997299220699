import React from 'react'
import { Col, Row } from 'react-bootstrap'
import SearchBarAdminPenal from '../../../SearchBarAdminPenal/SearchBarAdminPenal'

function SearchBarBtns() {
  return (
    <div>
      <Row>
        <Col>
        <SearchBarAdminPenal/>
        </Col>
      </Row>
    </div>
  )
}

export default SearchBarBtns
