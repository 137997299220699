import React, { useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function FormAddnewuser() {
    const location = useLocation();
    const userData = location.state?.userData || {};
    const navigate = useNavigate();
    const [handle, setHandle] = useState({
        name: userData.name || '',
        email: userData.email || '',
        phoneno: userData.phoneno || '',
        password: userData.password || '',
        dateofbirth: userData.dateofbirth || ''
    });
    const [error, setError] = useState({});
    const [isEditMode, setIsEditMode] = useState(!!userData._id);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setHandle({ ...handle, [name]: value });

        // Clear the error for the input field being changed
        if (error[name]) {
            setError({ ...error, [name]: '' });
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validateInputs = () => {
        let validationErrors = {};

        // Basic required field validations
        if (!handle.name) validationErrors.name = 'Name is required.';
        if (!handle.email) {
            validationErrors.email = 'Email is required.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(handle.email)) {
            validationErrors.email = 'Invalid email address.';
        }
        if (!handle.phoneno) {
            validationErrors.phoneno = 'Phone number is required.';
        } else if (handle.phoneno.includes(' ')) {
            validationErrors.phoneno = 'Phone number cannot contain spaces.';
        } else if (!/^\+?\d+$/.test(handle.phoneno)) {
            validationErrors.phoneno = 'Phone number must contain only numbers.';
        } else if (handle.phoneno.length < 10) {
            validationErrors.phoneno = 'Phone number must be at least 10 digits.';
        } else if (handle.phoneno.length > 12) {
            validationErrors.phoneno = 'Phone number must not exceed 12 digits.';
        }
        if (!handle.password) validationErrors.password = 'Password is required.';
        else if (handle.password.length < 8) {
            validationErrors.password = 'Password must be at least 8 characters long.';
        }
        if (!handle.dateofbirth) validationErrors.dateofbirth = 'Date of birth is required.';

        return validationErrors;
    };

    const handleFormData = async (e) => {
        e.preventDefault();

        const validationErrors = validateInputs();
        if (Object.keys(validationErrors).length > 0) {
            setError(validationErrors);
            return;
        }

        setIsLoading(true);  // Set loading state

        try {
            const payload = {
                name: handle.name,
                email: handle.email,
                phoneno: handle.phoneno,
                password: handle.password,
                dateofbirth: handle.dateofbirth
            };

            const url = isEditMode ? `https://backend.bkabab.com/user/${userData._id}` : 'https://backend.bkabab.com/adduser';
            const method = isEditMode ? 'PUT' : 'POST';

            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                const errorText = await response.text();
                if (response.status === 400 && errorText === 'Email already exists') {
                    setError({ email: 'Email already exists' });
                    return;
                }
                throw new Error(`Failed to ${isEditMode ? 'update' : 'create'} user: ${response.statusText}`);
            }

            toast.success(`User ${isEditMode ? 'updated' : 'added'} successfully`, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            setHandle({
                name: '',
                email: '',
                phoneno: '',
                password: '',
                dateofbirth: ''
            });

            navigate('/userinfo');
        } catch (error) {
            console.error(`Error ${isEditMode ? 'updating' : 'creating'} user:`, error.message);
            toast.error(`Error ${isEditMode ? 'updating' : 'creating'} user: ${error.message}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } finally {
            setIsLoading(false);  // Reset loading state
        }
    };

    return (
        <div className='one-form-addnew-adminuser'>
            <div className='second-form-addnew-adminuser'>
                <Row className='main-rowadmin-form'>
                    <Col xs={11} md={10} xl={8}>
                        <Form>
                            {error.form && <p className='error-form'>{error.form}</p>}
                            <Form.Group className='input-form-addadminuser' controlId="formBasicName">
                                <Form.Label className='adddish-input-lable'>Name</Form.Label>
                                <Form.Control
                                    name='name'
                                    type="text"
                                    placeholder="Name *"
                                    value={handle.name}
                                    onChange={handleInput}
                                    isInvalid={!!error.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='input-form-addadminuser' controlId="formBasicPhoneNo">
                                <Form.Label className='adddish-input-lable'>Phone No.</Form.Label>
                                <Form.Control
                                    name='phoneno'
                                    type="number"
                                    placeholder="Phone No. *"
                                    value={handle.phoneno}
                                    onChange={handleInput}
                                    isInvalid={!!error.phoneno}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.phoneno}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='input-form-addadminuser' controlId="formBasicEmail">
                                <Form.Label className='adddish-input-lable'>Email address</Form.Label>
                                <Form.Control
                                    name='email'
                                    type="email"
                                    placeholder="E-mail *"
                                    value={handle.email}
                                    onChange={handleInput}
                                    isInvalid={!!error.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='input-form-addadminuser' controlId="formBasicDateOfBirth">
                                <Form.Label className='adddish-input-lable'>Date of Birth</Form.Label>
                                <Form.Control
                                    name='dateofbirth'
                                    type="date"
                                    value={handle.dateofbirth}
                                    onChange={handleInput}
                                    isInvalid={!!error.dateofbirth}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {error.dateofbirth}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='input-form-addadminuser' controlId="formBasicPassword">
                                <Form.Label className='adddish-input-lable'>Password</Form.Label>
                                <div className='password-input-container'>
                                    <Form.Control
                                        name='password'
                                        type={showPassword ? "text" : "password"}
                                        value={handle.password}
                                        onChange={handleInput}
                                        isInvalid={!!error.password}
                                    />
                                    <Form.Control.Feedback className='form-adminpassword' type="invalid">
                                        {error.password}
                                    </Form.Control.Feedback>
                                    <span 
                                        className='password-toggle-icon' 
                                        onClick={togglePasswordVisibility}
                                        aria-label={showPassword ? 'Hide password' : 'Show password'}
                                    >
                                        {showPassword ? '👁️' : '🙈'}
                                    </span>
                                </div>
                            </Form.Group>
                            <Button 
                                onClick={handleFormData} 
                               className='form-submit-link-adddishes'
                                type="submit" 
                                disabled={isLoading}
                            >
                                {isLoading ? <Spinner animation="border" size="sm" /> : (isEditMode ? 'Update' : 'Submit')}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default FormAddnewuser;
