import React from 'react';

function Header() {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const Image = user?.image || 'default-profile.png'; // Default image if none is provided
  const firstName = user?.firstName || 'Admin';
  const lastName = user?.lastName || 'Admin';

  return (
    <div>
      <div className='main-header-admin-penal'> 
        <div className='header-admin-penal'>
          <img src='./kabab-house-Left-Right.jpg' className='image-fluid logo-admin-penal-header' />
        </div>
        <div className='main-profile-admin-penal'>
          <div className='admin-profile'>
            <img src={`https://backend.bkabab.com/foodimage/${Image}`} alt="Admin Profile" className="profile-image" />
            <div className="admin-name"><p>{firstName} </p> &nbsp; <p> {lastName}</p></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
