import React, { useState, useEffect } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useKababContext } from '../../../KababContext';
import ModelAddress from '../Home/Session-2/ModelAddress';
import ModelPickup from '../Home/Session-2/ModelPickup';
import UserFormedit from './UserFormedit';
import axios from 'axios';
import { toast } from 'react-toastify';

function BuyNowContent() {
    // Ensure useState is called at the top level
    const [showusereditModal, setShowuserediModal] = useState(false); 
    const [userData, setUserData] = useState({
        name: '',
        phoneno: '',
        email: ''
    });
    const [userOrder, setUserOrder] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [specialInstruction, setSpecialInstruction] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('Cash on Delivery'); 
    const [isOrderConfirmed, setIsOrderConfirmed] = useState(false);  // State for button disabled

    const { selectedLocation, setCartItems, cartItems, increaseQty, decreaseQty, handleShowAddressModal, showAddressModal, handleCloseAddressModal, handleShowPickupModal, showPickupModal, handleClosePickupModal } = useKababContext();
    const navigate = useNavigate();

    useEffect(() => {
        const storedUserData = sessionStorage.getItem('user');
        const storedCartItems = sessionStorage.getItem('cartItems');

        if (storedUserData || storedCartItems) {
            try {
                const parsedUserData = storedUserData ? JSON.parse(storedUserData) : {};
                const parsedCartItems = storedCartItems ? JSON.parse(storedCartItems) : [];

                setUserData({
                    name: parsedUserData.name || '',
                    phoneno: parsedUserData.phoneno || '',
                    email: parsedUserData.email || '',
                });

                const totalAmount = parsedCartItems.reduce((total, item) => total + (parseFloat(item.price) * parseInt(item.quantity, 10)), 0);
                setTotalAmount(totalAmount);

                const formattedOrder = parsedCartItems.map(item => ({
                    _id: item._id,
                    dishname: item.dishname,
                    dishimage: item.dishimage,
                    quantity: parseInt(item.quantity, 10),
                    totalAmount: parseFloat(item.price) * parseInt(item.quantity, 10),
                }));
                setUserOrder(formattedOrder);
            } catch (error) {
                console.error('Error parsing session storage data:', error);
            }
        }
    }, [cartItems]);

    const handleShowedituser = () => {
        setShowuserediModal(true);
    };

    const handleCloseedituser = () => setShowuserediModal(false);

    const handleUserSave = (updatedUserData) => {
        setUserData(updatedUserData);
    };
    const handleConfirmOrder = async () => {
        if (isOrderConfirmed) return;  // Prevent multiple clicks
    
        setIsOrderConfirmed(true);  // Disable button
    
        try {
            // Update addToCartCount for each dish in the cart
            await Promise.all(userOrder.map(async (item) => {
                await axios.post(`https://backend.bkabab.com/item/addtocart/${item._id}`, {
                    quantity: item.quantity
                });
            }));
    
            // Send order confirmation data to the backend
            const response = await axios.post('https://backend.bkabab.com/placeorder', {
                name: userData.name,
                phoneno: userData.phoneno,
                email: userData.email,
                location: selectedLocation,
                specialInstruction,
                items: userOrder,
                totalAmount,
                paymentMethod
            });
            
            // Notify user of success
            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
    
            // Generate WhatsApp message URL
            const orderDetails = `Order Details:\nName: ${userData.name}\nPhone: ${userData.phoneno}\nEmail: ${userData.email}\nLocation: ${selectedLocation}\nItems: ${userOrder.map(item => `${item.dishname} x${item.quantity} = ${item.totalAmount.toFixed(2)} $`).join(', ')}\nTotal Amount: ${totalAmount.toFixed(2)} $\nSpecial Instructions: ${specialInstruction}`;
            
            const encodedOrderDetails = encodeURIComponent(orderDetails);
            const whatsappURL = `https://wa.me/+97316161000?text=${encodedOrderDetails}`;
            
            // Open WhatsApp with pre-filled message
            window.open(whatsappURL, '_blank');
    
            // Reset cart-related state variables
            setUserOrder([]);
            setTotalAmount(0);
            setSpecialInstruction('');
            setCartItems([]);
            sessionStorage.removeItem('cartItems');
            navigate('/');
    
        } catch (error) {
            console.error('Error confirming order:', error);
            alert('Failed to confirm order: ' + error.message);
            setIsOrderConfirmed(false);  // Re-enable button if there's an error
        }
    };
    
    
    

    return (
        <div className='buynow-content'>
            <Container fluid className='header-container-main'>
                <p className='buynow-main-tital'>Shopping Cart</p>
                <Row>
                    <Col xl={4} xxl={3} className='order-summary-column-1'>
                        <div className='order-summary'>
                            <p className='order-tital-buynow'>Your Order</p>
                            {userOrder.map((item, index) => (
                                <div key={item._id} className='order-item'>
                                    <img src={`https://backend.bkabab.com/foodimage/${item.dishimage}`} className='image-fluid image-cart-buynow' />
                                    <div className='mt-2 buynow-cart-detail'>
                                        <p>{item.dishname}</p>
                                        <p>{item.totalAmount.toFixed(2)} $</p>
                                        <div className="quantity-controls">
                                            <button className='button-minus' onClick={() => decreaseQty(index)}>-</button>
                                            <span>{item.quantity}</span>
                                            <button className='button-plus' onClick={() => increaseQty(index)}>+</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <hr />
                    </Col>
                    <Col xl={8} xxl={9} className='detail-summary-column'>
                        <div className='buynow-customer-detail'>
                            <p className='order-tital-buynow'>Your Details</p>
                            <div className='mb-3 buynow-customer-detail-main'>
                                <div className='main-detail-nameemailphone'>
                                    <p><i className="las la-check-circle"></i>&nbsp;{userData.name}</p>
                                    <p><i className="las la-check-circle"></i>&nbsp;{userData.email}</p>
                                    <p><i className="las la-check-circle"></i>&nbsp;{userData.phoneno}</p>
                                </div>
                                <Link onClick={handleShowedituser} className="link-edit-user-detail">Edit</Link>
                            </div>
                            <div>
                                <p className='order-tital-buynow'>Your Address</p>
                                <div className='buynow-customer-detail-address'>
                                    <div className='main-detail-address'>
                                        <p><i className="las la-check-circle"></i>&nbsp;{selectedLocation}</p>
                                    </div>
                                    <Link onClick={handleShowAddressModal} className="link-edit-user-detail">Edit</Link>
                                </div>
                            </div>
                            <div>
                                <div className='buynow-customer-detail-payment'>
                                    <div className="mt-5">
                                        <p className='order-tital-buynow'>Payment Method</p>
                                        <div>
                                            <Form.Check
                                                inline
                                                label="Cash on Delivery"
                                                name="paymentMethod"
                                                type='radio'
                                                checked={paymentMethod === 'Cash on Delivery'}
                                                onChange={() => setPaymentMethod('Cash on Delivery')}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <Form.Check
                                                inline
                                                label="Online Bank Transfer"
                                                name="paymentMethod"
                                                type='radio'
                                                checked={paymentMethod === 'Online Bank Transfer'}
                                                onChange={() => setPaymentMethod('Online Bank Transfer')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='buynow-customer-detail-order-summary'>
                                    <p className='order-tital-buynow'>Add Instruction</p>
                                    <input
                                        className='input-forspecial-instruction-buynow'
                                        type='text'
                                        placeholder=' Special Instructions'
                                        value={specialInstruction}
                                        onChange={(e) => setSpecialInstruction(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='tital-price-buynow-yourcart'>
                                    <div className='d-flex'>
                                        <p className='price-total-tital'>Total Price :</p>
                                        <p className='price-total-buynow'>{totalAmount.toFixed(2)} $</p>
                                    </div>
                                </div>
                                <Link
                                    onClick={handleConfirmOrder}
                                    className={`confirmedorder-link-buynow ${isOrderConfirmed ? 'disabled' : ''}`}
                                    style={{ pointerEvents: isOrderConfirmed ? 'none' : 'auto' }}
                                >
                                    {isOrderConfirmed ? 'Order Confirmed' : 'Confirmed Order'}
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ModelAddress show={showAddressModal} handleClose={handleCloseAddressModal} handleShowPickup={handleShowPickupModal} />
            <ModelPickup show={showPickupModal} handleClose={handleClosePickupModal} handleShowAddress={handleShowAddressModal} />
            <UserFormedit show={showusereditModal} handleClose={handleCloseedituser} onSave={handleUserSave} />
        </div>
    );
}

export default BuyNowContent;
