import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

function Card1() {
  const [totalItems, setTotalItems] = useState(0);

  // Function to calculate the percentage dynamically
  const calculatePercentage = (items) => {
    if (items < 100) {
      return items; // Direct percentage if less than 100
    } else if (items < 1000) {
      return (items / 1000) * 100; // Percentage out of 1000
    } else if (items < 10000) {
      return (items / 10000) * 100; // Percentage out of 10000
    } else if (items < 100000) {
      return (items / 100000) * 100; // Percentage out of 10000
    }else if (items < 1000000) {
      return (items / 1000000) * 100; // Percentage out of 10000
    }
    // You can continue adding more ranges as needed
    return 100; // Cap at 100% for anything beyond 10000
  };

  useEffect(() => {
    const fetchTotalItems = async () => {
      try {
        const response = await fetch('https://backend.bkabab.com/totalitems');
        const data = await response.json();
        setTotalItems(data.totalItems);
      } catch (error) {
        console.error('Error fetching total items:', error);
      }
    };

    fetchTotalItems();

    // Set up interval to fetch data every 1 seconds
    const intervalId = setInterval(() => {
      fetchTotalItems();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Calculate the percentage for the circular progress bar
  const percentage = calculatePercentage(totalItems);

  return (
    <div className='card-main-body-dashbord'>
      <Row>
        <Col>
          <p className='adminpenal-card-tital'>Total Dishes</p>
          <p className='adminpenal-card-value'>{totalItems}</p>
        </Col>
        <Col className='progressCol-dashbord-cards'>
          <div className='progressContainer-main-container-dashbord'>
            <CircularProgressbar
              value={percentage}
              styles={buildStyles({
                pathColor: `#006e6b`,
              })}
            />
            <i className="las la-utensils icons-cards-dashbord"></i>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Card1;
