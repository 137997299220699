import React from 'react'
import '../../CSS/User.css'
import Header from '../../Components/Header/Header'
import TopHeader from '../../Components/Header/TopHeader'
import Coursel from './Session-1/Coursel'
import TimePickupDelivery from './Session-2/TimePickupDelivery'
import CategoryCardSearchBar from './Session-3/CategoryCardSearchBar'
import Footer from '../../Components/Footer/Footer'

function Home() {
  return (
    <div>
      <div>
        <TopHeader />
        <div className='Header-user-sight'>
        <Header />
        </div>
      </div>
      <div className='coursel-homeadd'>
        <Coursel />
      </div>
      <div>
      <TimePickupDelivery />
      </div>
      <div>
        <CategoryCardSearchBar/>
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  )
}

export default Home
