import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

function ClosedMenu() {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);
    const [pendingCount, setPendingCount] = useState();

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);
    const handleClearSession = () => {
        toast.info('Logout SuccessFully', {position: "top-right", autoClose: 1000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", });
        sessionStorage.clear();
    };

    useEffect(() => {
        const fetchOrderCounts = async () => {
            try {
                const pendingResponse = await fetch('https://backend.bkabab.com/orders/count/pending');
                const pendingData = await pendingResponse.json();
                setPendingCount(pendingData.pending);
            } catch (error) {
                console.error('Error fetching order counts:', error);
            }
        };
        // Initial fetch
        fetchOrderCounts();
        const intervalId = setInterval(() => {
            fetchOrderCounts();
        }, 500);
        return () => clearInterval(intervalId);
    }, []);
    return (
        <div>
            <div className='leptop-oruperscreen'>
                <div className='main-close-side-bar-leptop'>
                    <div>
                        <div className={`leftasidemenu-closed ${activeLink === '/dashboard' ? 'active' : ''}`}>
                            <Link id='links' to='/dashboard'>
                                <div id='imain-close-menu'>
                                    <i class="las la-tachometer-alt"></i>
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed ${activeLink === '/orders' ? 'active' : ''}`}>
                            <Link id='links' to='/orders'>
                                <div id='imain-close-menu'>
                                    <i class="las la-luggage-cart"></i>
                                    {pendingCount > 0 && <p className='count-order-show-closemenu'>{pendingCount}</p>}
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed ${activeLink === '/history' ? 'active' : ''}`}>
                            <Link id='links' to='/history'>
                                <div id='imain-close-menu'>
                                    <i class="las la-history"></i>
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed ${activeLink === '/alldishes' ? 'active' : ''}`}>
                            <Link id='links' to='/alldishes'>
                                <div id='imain-close-menu'>
                                    <i class="las la-stream"></i>
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed ${activeLink === '/adddishes' ? 'active' : ''}`}>
                            <Link id='links' to='/adddishes'>
                                <div id='imain-close-menu'>
                                    <i class="las la-cart-plus"></i>
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed ${activeLink === '/userinfo' ? 'active' : ''}`}>
                            <Link id='links' to='/userinfo'>
                                <div id='imain-close-menu'>
                                    <i class="las la-users"></i>
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed ${activeLink === '/adduser' ? 'active' : ''}`}>
                            <Link id='links' to='/adduser'>
                                <div id='imain-close-menu'>
                                    <i class="las la-user-tie"></i>
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed ${activeLink === '/admininfo' ? 'active' : ''}`}>
                            <Link id='links' to='/admininfo'>
                                <div id='imain-close-menu'>
                                    <i class="las la-user-lock"></i>
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed ${activeLink === '/addadmin' ? 'active' : ''}`}>
                            <Link id='links' to='/addadmin'>
                                <div id='imain-close-menu'>
                                    <i class="las la-user-plus"></i>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <div className='leftasidemenu-closed'>
                            <Link onClick={handleClearSession} id='links' to='/'>
                                <div id='imain-close-menu'>
                                    <i class="las la-sign-out-alt"></i>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tab-to-mobile-screens'>
                <div className='main-close-side-bar-mobile-tab'>
                    <div>
                        <div className={`leftasidemenu-closed-tab-mobile ${activeLink === '/dashboard' ? 'active' : ''}`}>
                            <Link id='links-tab-mobile' to='/dashboard'>
                                <div id='imain-close-menu-tab-mobile'>
                                    <i class="las la-tachometer-alt"></i>
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed-tab-mobile ${activeLink === '/orders' ? 'active' : ''}`}>
                            <Link id='links-tab-mobile' to='/orders'>
                                <div id='imain-close-menu-tab-mobile'>
                                    <i class="las la-luggage-cart"></i>
                                    {pendingCount > 0 && <p className='count-order-show-closemenu'>{pendingCount}</p>}
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed-tab-mobile ${activeLink === '/history' ? 'active' : ''}`}>
                            <Link id='links-tab-mobile' to='/history'>
                                <div id='imain-close-menu-tab-mobile'>
                                    <i class="las la-history"></i>
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed-tab-mobile ${activeLink === '/alldishes' ? 'active' : ''}`}>
                            <Link id='links-tab-mobile' to='/alldishes'>
                                <div id='imain-close-menu-tab-mobile'>
                                    <i class="las la-stream"></i>
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed-tab-mobile ${activeLink === '/adddishes' ? 'active' : ''}`}>
                            <Link id='links-tab-mobile' to='/adddishes'>
                                <div id='imain-close-menu-tab-mobile'>
                                    <i class="las la-cart-plus"></i>
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed-tab-mobile ${activeLink === '/userinfo' ? 'active' : ''}`}>
                            <Link id='links-tab-mobile' to='/userinfo'>
                                <div id='imain-close-menu-tab-mobile'>
                                    <i class="las la-users"></i>
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed-tab-mobile ${activeLink === '/adduser' ? 'active' : ''}`}>
                            <Link id='links-tab-mobile' to='/adduser'>
                                <div id='imain-close-menu-tab-mobile'>
                                    <i class="las la-user-tie"></i>
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed-tab-mobile ${activeLink === '/admininfo' ? 'active' : ''}`}>
                            <Link id='links-tab-mobile' to='/admininfo'>
                                <div id='imain-close-menu-tab-mobile'>
                                    <i class="las la-user-lock"></i>
                                </div>
                            </Link>
                        </div>
                        <div className={`leftasidemenu-closed-tab-mobile ${activeLink === '/addadmin' ? 'active' : ''}`}>
                            <Link id='links-tab-mobile' to='/addadmin'>
                                <div id='imain-close-menu-tab-mobile'>
                                    <i class="las la-user-plus"></i>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <div className='leftasidemenu-closed-tab-mobile'>
                            <Link onClick={handleClearSession} id='links-tab-mobile' to='/'>
                                <div id='imain-close-menu-tab-mobile'>
                                    <i class="las la-sign-out-alt"></i>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClosedMenu;