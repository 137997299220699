import React from 'react'
import TopFooter from './TopFooter'
import BottemFooter from './BottemFooter'

function Footer() {
  return (
    <div>
      <div>
        <TopFooter/>
      </div>
      <div>
        <BottemFooter/>
      </div>
    </div>
  )
}

export default Footer
