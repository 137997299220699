import React from 'react'
import { useKababContext } from '../../../../KababContext';
import { Col, Container, Row } from 'react-bootstrap';
import UserinfoSearchBar from './UserinfoSearchBar/UserinfoSearchBar';
import UserInfoData from './UserInfoData/UserInfoData';

function UserInfoContent() {
  const { sidebarOpen } = useKababContext();
  const contentStyle = sidebarOpen ? 'Side-bar-closed' : 'Side-bar-open';
  return (
    <div className={contentStyle}>
      <p className='main-tital-adminpenal'>
        User Information
      </p>
      <Container fluid>
        <Row>
          <Col md={12}><UserinfoSearchBar/></Col>
          <Col md={12}><UserInfoData/></Col>
        </Row>
      </Container>
    </div>
  )
}

export default UserInfoContent
