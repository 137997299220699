import React, { useState, useEffect } from 'react';
import { Modal ,Alert  } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useKababContext } from '../../../../KababContext';

function ModelAddress({ show, handleClose, handleShowPickup }) {
    const { handleSaveSelection } = useKababContext();
    const [searchTerm, setSearchTerm] = useState('');
    const [locations, setLocations] = useState([
        'Bahrain National Museum',
        'Bab Al Bahrain',
        'Al-Fateh Grand Mosque',
        'Beit Al Quran',
        'Moda Mall',
        'Manama Souq',
        'Bahrain Financial Harbour',
        'Seef Mall',
        'The Avenues Bahrain',
        'Qalat al-Bahrain Bahrain Fort',
        'Bahrain City Centre',
        'Arad Fort',
        'Adhari Park',
        'Tree of Life',
        'Riffa Fort',
        'Muharraq Souq',
        'Aali Burial Mounds',
        'Dilmun Burial Mounds',
        'Lost Paradise of Dilmun Water Park',
        'Royal Camel Farm',
        'Saar Archaeological Site',
        'Bahrain International Circuit',
        'Al Dar Islands',
        'Sitra Park',
        'Prince Khalifa Bin Salman Park',
        'Dragon City Bahrain',
        'Al Areen Wildlife Park',
        'Al Jasra House',
        'Oil Museum and first oil well',
        'Bahrain World Trade Center',
        'Water Garden City',
        'Juffair Mall',
        'Block 338',
        'Reef Island',
        'Amwaj Islands',
        'La Fontaine Centre of Contemporary Art',
        'Bait Al-Jasra (Jasra House)',
        'Bahrain Bay',
        'Al Riwaq Art Space',
        'Avenues Park',
        'King Fahd Causeway',
        'Museum of Pearl Diving',
        'City Centre Bahrain',
        'Marassi Beach',
        'The Lagoon Park',
        'Al Fateh Corniche',
        'A’ali Pottery Workshops',
        'Rashid Al Oraifi Museum',
        'Khamis Mosque',
        'Shaikh Isa bin Ali House',
        'Arad Bay Protected Area and Park',
        'Qalat al Bahrain Site and Museum',
        'Muharraq Island',
        'Al Jazeera Beach',
        'Bu Maher Fort',
        'Al Khamis Mosque',
        'Bahrain Pearling Trail',
        'National Theatre of Bahrain',
        'Gudaibiya Palace',
        'Tree of Life Visitor Center',
        'Muharraq Houses',
        'Shaikh Ebrahim Center',
        'Busaad Art Gallery',
        'Pearl Monument',
        'Prince Khalifa Bin Salman Park',
        'Albareh Art Gallery',
        'Old Houses of Muharraq',
        'Kurayzi House',
        'Al-Muntazah Beach',
        'Dolphin Resort Bahrain',
        'King Faisal Corniche',
        'Al Dana Amphitheatre',
        'Vertical Garden',
        'Jassim Zainals House',
        'Al Nuzul Guest House',
        'Bu Maher Causeway',
        'Siyadi House',
        'Saar Mall',
        'Al Areen Palace & Spa',
        'Andalus Garden',
        'Sanabis Mosque',
        'Bahrain National Theater',
        'Sheikh Salman Bin Ahmed Al Fateh Fort',
        'Khalid Al Khajah Park',
        'Yateem Centre',
        'Alba Potline 6 Expansion Project',
        'Al Jasra Handicraft Centre',
        'Sitra Fishermans Port',
        'Dilmunia Island',
        'The Courtyard (Almoayyed Tower)',
        'Zallaq Springs',
        'University of Bahrain',
        'Al-Malikiyah Beach',
        'Saar Mall',
        'Durrat Al Bahrain',
        'Bahrain Specialist Hospital',
        'Bahrain Financial Harbor',
        'Sheikh Salman bin Ahmed Al-Fateh Fort (Riffa Fort)',
        'Marassi Galleria',
        'Riffa Fort (Sheikh Salman Bin Ahmed Al Fateh Fort)',
        'Riffa Views International School',
        'Royal Golf Club',
        'Bahrain National Stadium',
        'Riffa Mall',
        'Riffa Bazaar',
        'Al Enma Mall',
        'Riffa Clock Tower',
        'Al Reem Center',
        'American Mission Hospital Riffa',
        'Riffa Palace',
        'West Riffa Park',
        'East Riffa Park',
        'Riffa Souq',
        'Shaikh Khalifa bin Salman Institute of Technology',
        'Bahrain Rugby Football Club',
        'Al Jazeera Supermarket',
        'Kanoo Mosque',
        'Bin Hindi Motors',
        'Noor Al Diyar Private School',
        'Riffa Medical Center',
        'Riffa Views Lagoon',
        'Riffa Water Garden',
        'Al Jawad Bakery',
        'Al Rawabi Private School',
        'Al Hekma International School',
        'Al Reem Bakery',
        'Riffa Walking Track',
        'Al Najma Club',
        'Riffa Sports Club',
        'City Centre Riffa',
        'Al Saada Primary Girls School',
        'Al Bustan Pharmacy',
        'Bahrain Specialist Hospital',
        'Riffa International School',
        'Jabriya Secondary Boys School',
        'Riffa Gate Mosque',
        'Al Jazeera Pharmacy',
        'A`ali Burial Mounds',
        'Bahrain Tennis Federation',
        'Riffa Views Golf Academy',
        'Royal University for Women',
        'Sheikh Isa Bin Salman Library',
        'Al Bader Mosque',
        'Kanoo Garden',
        'West Riffa Health Centre',
        'Al Reem Restaurant',
        'Hilton Garden Inn Bahrain Bay',
        'Gulf University',
        'Riffa Youth Centre',
        'Awal Private School',
        'Riffa Meat and Vegetable Market',
        'Al Noor International School',
        'Shaikh Khalifa Park',
        'Riffa Post Office',
        'Riffa Flower Shop',
        'Al Riffa Central Market',
        'Riffa Pharmacy',
        'Riffa View Mall',
        'Almoayyed Tower',
        'Riffa Culture Center',
        'Riffa Medical Complex',
        'Al Hekma Medical Complex',
        'Riffa Public Library',
        'Al Areen Wildlife Park',
        'Riffa Central Clinic',
        'Riffa Health Center',
        'Bahrain Defence Force Hospital',
        'Shaikh Khalifa Institute for Technology',
        'Royal Golf Club Academy',
        'Alosra Supermarket',
        'Riffa Grand Mosque',
        'The Indian School Bahrain',
        'Bahrain Defence Force Royal Medical Services',
        'Riffa Driving School',
        'Al Salam Specialist Hospital',
        'Al Hilal Hospital',
        'Awal Hotel',
        'Royal College of Surgeons in Ireland',
        'Sheikh Isa Cultural Centre',
        'Riffa Public Park',
        'Royal University for Women Campus',
        'Riffa Golf Club',
        'Riffa Palms',
        'West Riffa Library',
        'East Riffa Library',
        'Riffa Souq Market',
        'Awali Private School',
        'Riffa National Stadium',
        'Shaikh Khalifa Institute',
        'Riffa South',
        'West Riffa',
        'East Riffa',
        'North Riffa',
        'South Riffa',
        'Riffa General Hospital',
        'Riffa Club',
        'Riffa View',
        'Al Enma Mall Riffa',
        'Riffa Fort Museum',
        'Awali Hospital',
        'Awali Golf Club',
        'Awali Community Center',
        'Awali Market',
        'Awali Primary School',
        'Awali Public Park',
        'Awali Guest House',
        'Awali Riding Club',
        'Awali Tennis Club',
        'Awali Bowling Alley',
        'Awali Scout Group',
        'Awali Mosque',
        'Awali Clubhouse',
        'Awali Post Office',
        'Awali Library',
        'Awali Kindergarten',
        'Awali Rugby Club',
        'Awali Village',
        'Awali Cricket Ground',
        'Awali Community Hall',
        'Awali Swimming Pool',
        'Awali Fitness Center',
        'Awali Cultural Center',
        'Awali Theatre',
        'Awali Skating Rink',
        'Awali Coffee Shop',
        'Awali Supermarket',
        'Awali Medical Clinic',
        'Awali Pharmacy',
        'Awali Dental Clinic',
        'Awali Wellness Center',
        'Awali Veterinary Clinic',
        'Awali Art Gallery',
        'Awali Craft Center',
        'Awali Dance Studio',
        'Awali Music School',
        'Awali Yoga Studio',
        'Awali Gym',
        'Awali Squash Court',
        'Awali Park',
        'Awali Playground',
        'Awali Picnic Area',
        'Awali BBQ Area',
        'Awali Rose Garden',
        'Awali Community Garden',
        'Awali Fitness Trail',
        'Awali Jogging Track',
        'Awali Cycling Path',
        'Awali Horse Riding Track',
        'Awali Sports Field',
        'Awali Football Pitch',
        'Awali Basketball Court',
        'Awali Netball Court',
        'Awali Volleyball Court',
        'Awali Badminton Court',
        'Awali Archery Range',
        'Awali Shooting Range',
        'Awali Fishing Pond',
        'Awali Lake',
        'Awali River',
        'Awali Waterfall',
        'Awali Fountain',
        'Awali Stream',
        'Awali Reservoir',
        'Awali Oasis',
        'Awali Desert Park',
        'Awali Mountain Park',
        'Awali National Park',
        'Awali State Park',
        'Awali City Park',
        'Awali Nature Reserve',
        'Awali Wildlife Sanctuary',
        'Awali Botanical Garden',
        'Awali Arboretum',
        'Awali Zoo',
        'Awali Safari Park',
        'Awali Bird Sanctuary',
        'Awali Butterfly Garden',
        'Awali Insectarium',
        'Awali Reptile House',
        'Awali Aquarium',
        'Awali Marine Park',
        'Awali Coral Reef',
        'Awali Beach',
        'Awali Shoreline',
        'Awali Coastline',
        'Awali Harbor',
        'Awali Marina',
        'Awali Pier',
        'Awali Dock',
        'Awali Boathouse',
        'Awali Lighthouse',
        'Awali Shipyard',
        'Awali Fishing Village',
        'Awali Market Square',
        'Awali Town Hall',
        'Awali Civic Center',
        'Awali Convention Center',
        'Awali Exhibition Hall',
        'Awali Concert Hall',
        'Nuwaidrat Park',
        'Nuwaidrat Primary School',
        'Nuwaidrat Supermarket',
        'Nuwaidrat Health Center',
        'Nuwaidrat Mosque',
        'Nuwaidrat Public Library',
        'Nuwaidrat Community Center',
        'Nuwaidrat Post Office',
        'Nuwaidrat Cultural Center',
        'Nuwaidrat Pharmacy',
        'Nuwaidrat Sports Club',
        'Nuwaidrat Football Field',
        'Nuwaidrat Shopping Mall',
        'Nuwaidrat Market',
        'Nuwaidrat Police Station',
        'Nuwaidrat Fire Station',
        'Nuwaidrat Bus Station',
        'Nuwaidrat Driving School',
        'Nuwaidrat Fitness Center',
        'Nuwaidrat Veterinary Clinic',
        'Nuwaidrat Dental Clinic',
        'Nuwaidrat Café',
        'Nuwaidrat Restaurant',
        'Nuwaidrat Bakery',
        'Nuwaidrat Barber Shop',
        'Nuwaidrat Beauty Salon',
        'Nuwaidrat Nursery',
        'Nuwaidrat Kindergarten',
        'Nuwaidrat Secondary School',
        'Nuwaidrat University',
        'Nuwaidrat College',
        'Nuwaidrat Language Center',
        'Nuwaidrat Music School',
        'Nuwaidrat Art Gallery',
        'Nuwaidrat Theater',
        'Nuwaidrat Cinema',
        'Nuwaidrat Bowling Alley',
        'Nuwaidrat Skating Rink',
        'Nuwaidrat Swimming Pool',
        'Nuwaidrat Golf Club',
        'Nuwaidrat Tennis Court',
        'Nuwaidrat Basketball Court',
        'Nuwaidrat Volleyball Court',
        'Nuwaidrat Handball Court',
        'Nuwaidrat Badminton Court',
        'Nuwaidrat Squash Court',
        'Nuwaidrat Martial Arts Center',
        'Nuwaidrat Yoga Studio',
        'Nuwaidrat Dance Studio',
        'Nuwaidrat Music Studio',
        'Nuwaidrat Recording Studio',
        'Nuwaidrat Photography Studio',
        'Nuwaidrat Printing Press',
        'Nuwaidrat Publishing House',
        'Nuwaidrat Bookstore',
        'Nuwaidrat Souq',
        'Nuwaidrat Fish Market',
        'Nuwaidrat Meat Market',
        'Nuwaidrat Vegetable Market',
        'Nuwaidrat Flower Shop',
        'Nuwaidrat Hardware Store',
        'Nuwaidrat Electronics Store',
        'Nuwaidrat Furniture Store',
        'Nuwaidrat Clothing Store',
        'Nuwaidrat Shoe Store',
        'Nuwaidrat Jewelry Store',
        'Nuwaidrat Toy Store',
        'Nuwaidrat Gift Shop',
        'Nuwaidrat Souvenir Shop',
        'Nuwaidrat Craft Center',
        'Nuwaidrat Workshop',
        'Nuwaidrat Garage',
        'Nuwaidrat Car Wash',
        'Nuwaidrat Petrol Station',
        'Nuwaidrat Car Rental',
        'Nuwaidrat Taxi Stand',
        'Nuwaidrat Auto Repair Shop',
        'Nuwaidrat Tire Shop',
        'Nuwaidrat Spare Parts Shop',
        'Nuwaidrat Hardware Store',
        'Nuwaidrat Construction Company',
        'Nuwaidrat Real Estate Office',
        'Nuwaidrat Property Management',
        'Nuwaidrat Engineering Office',
        'Nuwaidrat Architect Office',
        'Nuwaidrat Interior Design',
        'Nuwaidrat Furniture Shop',
        'Nuwaidrat Home Decor',
        'Nuwaidrat Garden Center',
        'Nuwaidrat Nursery',
        'Nuwaidrat Florist',
        'Nuwaidrat Landscape Architect',
        'Nuwaidrat Building Materials',
        'Nuwaidrat Paint Store',
        'Nuwaidrat Tile Store',
        'Nuwaidrat Carpet Store',
        'Nuwaidrat Wallpaper Store',
        'Nuwaidrat Window Treatment',
        'Nuwaidrat Blinds Store',
        'Nuwaidrat Curtain Store',
        'Sanad Park',
        'Sanad Primary School',
        'Sanad Supermarket',
        'Sanad Health Center',
        'Sanad Mosque',
        'Sanad Public Library',
        'Sanad Community Center',
        'Sanad Post Office',
        'Sanad Cultural Center',
        'Sanad Pharmacy',
        'Sanad Sports Club',
        'Sanad Football Field',
        'Sanad Shopping Mall',
        'Sanad Market',
        'Sanad Police Station',
        'Sanad Fire Station',
        'Sanad Bus Station',
        'Sanad Driving School',
        'Sanad Fitness Center',
        'Sanad Veterinary Clinic',
        'Sanad Dental Clinic',
        'Sanad Café',
        'Sanad Restaurant',
        'Sanad Bakery',
        'Sanad Barber Shop',
        'Sanad Beauty Salon',
        'Sanad Nursery',
        'Sanad Kindergarten',
        'Sanad Secondary School',
        'Sanad University',
        'Sanad College',
        'Sanad Language Center',
        'Sanad Music School',
        'Sanad Art Gallery',
        'Sanad Theater',
        'Sanad Cinema',
        'Sanad Bowling Alley',
        'Sanad Skating Rink',
        'Sanad Swimming Pool',
        'Sanad Golf Club',
        'Sanad Tennis Court',
        'Sanad Basketball Court',
        'Sanad Volleyball Court',
        'Sanad Handball Court',
        'Sanad Badminton Court',
        'Sanad Squash Court',
        'Sanad Martial Arts Center',
        'Sanad Yoga Studio',
        'Sanad Dance Studio',
        'Sanad Music Studio',
        'Sanad Recording Studio',
        'Sanad Photography Studio',
        'Sanad Printing Press',
        'Sanad Publishing House',
        'Sanad Bookstore',
        'Sanad Souq',
        'Sanad Fish Market',
        'Sanad Meat Market',
        'Sanad Vegetable Market',
        'Sanad Flower Shop',
        'Sanad Hardware Store',
        'Sanad Electronics Store',
        'Sanad Furniture Store',
        'Sanad Clothing Store',
        'Sanad Shoe Store',
        'Sanad Jewelry Store',
        'Sanad Toy Store',
        'Sanad Gift Shop',
        'Sanad Souvenir Shop',
        'Sanad Craft Center',
        'Sanad Workshop',
        'Sanad Garage',
        'Sanad Car Wash',
        'Sanad Petrol Station',
        'Sanad Car Rental',
        'Sanad Taxi Stand',
        'Sanad Auto Repair Shop',
        'Sanad Tire Shop',
        'Sanad Spare Parts Shop',
        'Sanad Hardware Store',
        'Sanad Construction Company',
        'Sanad Real Estate Office',
        'Sanad Property Management',
        'Sanad Engineering Office',
        'Sanad Architect Office',
        'Sanad Interior Design',
        'Sanad Furniture Shop',
        'Sanad Home Decor',
        'Sanad Garden Center',
        'Sanad Nursery',
        'Sanad Florist',
        'Sanad Landscape Architect',
        'Sanad Building Materials',
        'Sanad Paint Store',
        'Sanad Tile Store',
        'Sanad Carpet Store',
        'Sanad Wallpaper Store',
        'Sanad Window Treatment',
        'Sanad Blinds Store',
        'Sanad Curtain Store',
        'Al Eker Park',
        'Al Eker Primary School',
        'Al Eker Supermarket',
        'Al Eker Health Center',
        'Al Eker Mosque',
        'Al Eker Public Library',
        'Al Eker Community Center',
        'Al Eker Post Office',
        'Al Eker Cultural Center',
        'Al Eker Pharmacy',
        'Al Eker Sports Club',
        'Al Eker Football Field',
        'Al Eker Shopping Mall',
        'Al Eker Market',
        'Al Eker Police Station',
        'Al Eker Fire Station',
        'Al Eker Bus Station',
        'Al Eker Driving School',
        'Al Eker Fitness Center',
        'Al Eker Veterinary Clinic',
        'Al Eker Dental Clinic',
        'Al Eker Café',
        'Al Eker Restaurant',
        'Al Eker Bakery',
        'Al Eker Barber Shop',
        'Al Eker Beauty Salon',
        'Al Eker Nursery',
        'Al Eker Kindergarten',
        'Al Eker Secondary School',
        'Al Eker University',
        'Al Eker College',
        'Al Eker Language Center',
        'Al Eker Music School',
        'Al Eker Art Gallery',
        'Al Eker Theater',
        'Al Eker Cinema',
        'Al Eker Bowling Alley',
        'Al Eker Skating Rink',
        'Al Eker Swimming Pool',
        'Al Eker Golf Club',
        'Al Eker Tennis Court',
        'Al Eker Basketball Court',
        'Al Eker Volleyball Court',
        'Al Eker Handball Court',
        'Al Eker Badminton Court',
        'Al Eker Squash Court',
        'Al Eker Martial Arts Center',
        'Al Eker Yoga Studio',
        'Al Eker Dance Studio',
        'Al Eker Music Studio',
        'Al Eker Recording Studio',
        'Al Eker Photography Studio',
        'Al Eker Printing Press',
        'Al Eker Publishing House',
        'Al Eker Bookstore',
        'Al Eker Souq',
        'Al Eker Fish Market',
        'Al Eker Meat Market',
        'Al Eker Vegetable Market',
        'Al Eker Flower Shop',
        'Al Eker Hardware Store',
        'Al Eker Electronics Store',
        'Al Eker Furniture Store',
        'Al Eker Clothing Store',
        'Al Eker Shoe Store',
        'Al Eker Jewelry Store',
        'Al Eker Toy Store',
        'Al Eker Gift Shop',
        'Al Eker Souvenir Shop',
        'Al Eker Craft Center',
        'Al Eker Workshop',
        'Al Eker Garage',
        'Al Eker Car Wash',
        'Al Eker Petrol Station',
        'Al Eker Car Rental',
        'Al Eker Taxi Stand',
        'Al Eker Auto Repair Shop',
        'Al Eker Tire Shop',
        'Al Eker Spare Parts Shop',
        'Al Eker Hardware Store',
        'Al Eker Construction Company',
        'Al Eker Real Estate Office',
        'Al Eker Property Management',
        'Al Eker Engineering Office',
        'Al Eker Architect Office',
        'Al Eker Interior Design',
        'Al Eker Furniture Shop',
        'Al Eker Home Decor',
        'Al Eker Garden Center',
        'Al Eker Nursery',
        'Al Eker Florist',
        'Al Eker Landscape Architect',
        'Al Eker Building Materials',
        'Al Eker Paint Store',
        'Al Eker Tile Store',
        'Al Eker Carpet Store',
        'Al Eker Wallpaper Store',
        'Al Eker Window Treatment',
        'Al Eker Blinds Store',
        'Al Eker Curtain Store',
        'Industrial Area Park',
        'Industrial Area Primary School',
        'Industrial Area Supermarket',
        'Industrial Area Health Center',
        'Industrial Area Mosque',
        'Industrial Area Public Library',
        'Industrial Area Community Center',
        'Industrial Area Post Office',
        'Industrial Area Cultural Center',
        'Industrial Area Pharmacy',
        'Industrial Area Sports Club',
        'Industrial Area Football Field',
        'Industrial Area Shopping Mall',
        'Industrial Area Market',
        'Industrial Area Police Station',
        'Industrial Area Fire Station',
        'Industrial Area Bus Station',
        'Industrial Area Driving School',
        'Industrial Area Fitness Center',
        'Industrial Area Veterinary Clinic',
        'Industrial Area Dental Clinic',
        'Industrial Area Café',
        'Industrial Area Restaurant',
        'Industrial Area Bakery',
        'Industrial Area Barber Shop',
        'Industrial Area Beauty Salon',
        'Industrial Area Nursery',
        'Industrial Area Kindergarten',
        'Industrial Area Secondary School',
        'Industrial Area University',
        'Industrial Area College',
        'Industrial Area Language Center',
        'Industrial Area Music School',
        'Industrial Area Art Gallery',
        'Industrial Area Theater',
        'Industrial Area Cinema',
        'Industrial Area Bowling Alley',
        'Industrial Area Skating Rink',
        'Industrial Area Swimming Pool',
        'Industrial Area Golf Club',
        'Industrial Area Tennis Court',
        'Industrial Area Basketball Court',
        'Industrial Area Volleyball Court',
        'Industrial Area Handball Court',
        'Industrial Area Badminton Court',
        'Industrial Area Squash Court',
        'Industrial Area Martial Arts Center',
        'Industrial Area Yoga Studio',
        'Industrial Area Dance Studio',
        'Industrial Area Music Studio',
        'Industrial Area Recording Studio',
        'Industrial Area Photography Studio',
        'Industrial Area Printing Press',
        'Industrial Area Publishing House',
        'Industrial Area Bookstore',
        'Industrial Area Souq',
        'Industrial Area Fish Market',
        'Industrial Area Meat Market',
        'Industrial Area Vegetable Market',
        'Industrial Area Flower Shop',
        'Industrial Area Hardware Store',
        'Industrial Area Electronics Store',
        'Industrial Area Furniture Store',
        'Industrial Area Clothing Store',
        'Industrial Area Shoe Store',
        'Industrial Area Jewelry Store',
        'Industrial Area Toy Store',
        'Industrial Area Gift Shop',
        'Industrial Area Souvenir Shop',
        'Industrial Area Craft Center',
        'Industrial Area Workshop',
        'Industrial Area Garage',
        'Industrial Area Car Wash',
        'Industrial Area Petrol Station',
        'Industrial Area Car Rental',
        'Industrial Area Taxi Stand',
        'Industrial Area Auto Repair Shop',
        'Industrial Area Tire Shop',
        'Industrial Area Spare Parts Shop',
        'Industrial Area Hardware Store',
        'Industrial Area Construction Company',
        'Industrial Area Real Estate Office',
        'Industrial Area Property Management',
        'Industrial Area Engineering Office',
        'Industrial Area Architect Office',
        'Industrial Area Interior Design',
        'Industrial Area Furniture Shop',
        'Industrial Area Home Decor',
        'Industrial Area Garden Center',
        'Industrial Area Nursery',
        'Industrial Area Florist',
        'Industrial Area Landscape Architect',
        'Industrial Area Building Materials',
        'Industrial Area Paint Store',
        'Industrial Area Tile Store',
        'Industrial Area Carpet Store',
        'Industrial Area Wallpaper Store',
        'Industrial Area Window Treatment',
        'Industrial Area Blinds Store',
        'Industrial Area Curtain Store',
    ]);
const [filteredResults, setFilteredResults] = useState([]);
    const [customLocation, setCustomLocation] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        setLocations(prevLocations => [...prevLocations].sort());
    }, []);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        setCustomLocation(value);
    };

    useEffect(() => {
        const filtered = locations.filter(location =>
            location.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredResults(filtered);
    }, [searchTerm, locations]);

    const handleLocationClick = (location) => {
        setSearchTerm(location);
        setCustomLocation(location);
        setError(''); // Clear any previous error
    };

    const handleSaveClick = () => {
        const locationToSave = customLocation || searchTerm;

        // Validation: Khali space, sirf special characters, ya sirf numbers save na hon
        const trimmedLocation = locationToSave.trim();
        const specialCharacterOnly = /^[!@#$%^&*(),.?":{}|<>]*$/.test(trimmedLocation);
        const isNumeric = /^[0-9]+$/.test(trimmedLocation);

        if (!trimmedLocation || specialCharacterOnly || isNumeric) {
            setError('Invalid location. Please enter a valid location name.');
            return;
        }

        if (locationToSave) {
            handleSaveSelection('location', locationToSave);
            handleClose();
            setError(''); // Clear error on successful save
        }
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
            <Modal.Header>
                <Modal.Title className='model-main'>
                    <img src='BkababLogo.jpg' width={70} alt='Bahrain Kebabs Logo' />
                    <p className='mode-tital'>Select Your Area</p>
                </Modal.Title>
                <Link variant="secondary" className='close-link-model' onClick={handleClose}>
                    X
                </Link>
            </Modal.Header>
            <Modal.Body>
                <div className='model1-main-body'>
                    <div className='model1-second'>
                        <Link className='deleviry-model-1-btn-1'>Delivery</Link>
                        <Link onClick={handleShowPickup} className='deleviry-model-1-btn-2'>Pick Up</Link>
                    </div>
                </div>
                <div className='model-search-bar-main-modeladdress'>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleInputChange}
                        placeholder="Enter Area - Street Name , No."
                        className='model-search-bar'
                    />
                </div>
                {error && (
                    <Alert variant="danger" className="mt-3">
                        {error}
                    </Alert>
                )}
                {searchTerm && (
                    <div className='location-results'>
                        {filteredResults.length > 0 ? (
                            <ul className="location-list">
                                {filteredResults.map(result => (
                                    <li key={result} onClick={() => handleLocationClick(result)} className='search-locationmodel'>
                                        {result}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No results found</p>
                        )}
                    </div>
                )}
                <div className='model-search-bar-main-modeladdress'>
                    <Link className='model-link-save' onClick={handleSaveClick}>
                        Save
                    </Link>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='models-footer-main'>
                    <div className='copy-right-text-bottom-footer'>
                        <p>Copyright © 2024, All Right Reserved. Powered By </p> &nbsp; &nbsp;<Link className='bottom-footer-links'> SGB WORLD </Link>&nbsp;&nbsp;
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModelAddress;
