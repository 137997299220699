import React from 'react'
import { useKababContext } from '../../../../KababContext';
import CategroyDishes from './Categroy/CategroyDishes';
import { Col, Container, Row } from 'react-bootstrap';
import DishesSearchBar from './DishesSearchBar/DishesSearchBar';
import SabCategory from './SabCategory/SabCategory';

function AllDishesContent() {
  const { sidebarOpen } = useKababContext();
  const contentStyle = sidebarOpen ? 'Side-bar-closed' : 'Side-bar-open';
  return (
    <div className={contentStyle}>
      <p className='main-tital-adminpenal'>
        Dishes
      </p>
      <Container fluid>
        <Row>
          <Col md={12}><CategroyDishes /></Col>
        </Row>
      </Container>
      <Container fluid>
        <Row >
          <Col md={12} ><DishesSearchBar /></Col>
        </Row>
      </Container>
      <Container fluid>
        <Row >
          <Col md={12} >
            <SabCategory  />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AllDishesContent
