import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import ModelAddress from './ModelAddress';
import ModelPickup from './ModelPickup';
import { useKababContext } from '../../../../KababContext';

function AddressPickup() {
  const { selectedLocation ,handleShowAddressModal , handleCloseAddressModal ,handleShowPickupModal ,handleClosePickupModal ,showAddressModal , showPickupModal} = useKababContext();

  return (
    <div className='address-main'>
      <Link className='Link-address-main' onClick={handleShowAddressModal}>
        <Row className='address-row'>
          <Col className='address-col'><i className="las la-map-marker-alt"></i></Col>
          <Col xs={9} className='address-col'>
            <p>{selectedLocation || 'Select Your Address'}</p>
          </Col>
        </Row>
      </Link>
      <ModelAddress show={showAddressModal} handleClose={handleCloseAddressModal} handleShowPickup={handleShowPickupModal} />
      <ModelPickup show={showPickupModal} handleClose={handleClosePickupModal} handleShowAddress={handleShowAddressModal} />
    </div>
  );
}

export default AddressPickup;
