import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'

function DelivaryComponent() {
  return (
    <div className='delivary-main'>
      <Row className='delevary-row'>
        <Col className='delevary-col'>
          <i class="las la-truck-moving"></i>
        </Col>
        <Col xs={9} className='delevary-col'>
          <p>
            fastest delivery in 45 minutes
          </p>
        </Col>
      </Row>
    </div>
  )
}

export default DelivaryComponent
