import React from 'react';
import ClosedMenu from './ClosedMenu';
import OpenMenu from './OpenMenu';
import { useKababContext } from '../../../KababContext';
import '../../CSS/AdmiStyle.css'

const AsideMenu = () => {
  const { sidebarOpen, toggleSidebar } = useKababContext();

  return (
    <div className='asidemenu-main-btn-or-menu'>
      <div id='asidemain0-leptop-screen'>
        <div className="loptop-screen-button-toggle" style={{ marginLeft: sidebarOpen ? '55px' : '170px' }}>
          <button className="side-bar-button-toggle" onClick={toggleSidebar}>
            {sidebarOpen ? '☰' : 'X'}
          </button>
        </div>
        {!sidebarOpen && (
          <div className={`sidebarclose ${sidebarOpen ? '' : 'close'}`}>
            <OpenMenu />
          </div>
        )}
        <div className={`sidebaropen ${sidebarOpen ? 'open' : ''}`}>
          <ClosedMenu />
        </div>
      </div>
      <div id='asidemain0-tab-screen'>
        <div className={`sidebar-tab-screen ${sidebarOpen ? '' : 'close'}`}>
          <ClosedMenu />
        </div>
      </div>
    </div>
  );
};

export default AsideMenu;
