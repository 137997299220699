import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function CategoryComponent({ activeCategory }) {
    const [data, setData] = useState([]);

    // Fetch Data Function
    const fetchApi = async () => {
        try {
            const response = await fetch("https://backend.bkabab.com/categories");
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            const value = await response.json();
            setData(value);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchApi();
    }, []);

    return (
        <div className='main-category-list-session-3'>
            <div className='main-category-list-1-session-3'>
                <h3>Category</h3>
                {data.map((item) => (
                        <Link className={`category-links-1 ${activeCategory === item.name ? 'active-link' : ''}`}>{item.name}</Link>
                    
                ))}
            </div>

            <div className='main-category-list-2-session-3'>
                <h3>Category</h3>

                <div className='mobile-screen-category'>
                    {data.map((item) => (
                        <Link key={item._id} className='category-links-2'>{item.name}</Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CategoryComponent;
