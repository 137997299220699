import React, { useEffect, useState } from 'react';
import { Table, Col, Row } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { useKababContext } from '../../../../../KababContext';
import moment from 'moment';

const OrderHistoryList = () => {
    const { searchbar } = useKababContext();
    const [data, setData] = useState([]);

    // Function for Fetching Data 
    const fetchApi = async () => {
        try {
            const response = await fetch("https://backend.bkabab.com/orders");
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            const value = await response.json();
            setData(value);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchApi();
    }, []);

    const pendingOrders = data.filter(order => {
        console.log('Order status:', order.status); // Log the status
        return order.status === 'canceled' || order.status === 'completed';
    });

    // Filter orders based on search term
    const filteredOrders = pendingOrders.filter(order => {
        const name = order.name?.toLowerCase() || "";
        const category = order.category?.toLowerCase() || "";
        return (name + ' ' + category).includes(searchbar.toLowerCase());
    });

    // Sort orders by creation time (latest first)
    const sortedOrders = filteredOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const formatTime = (createdAt) => {
        const time = moment(createdAt);
        const now = moment();
        const diffInHours = now.diff(time, 'hours');
        return diffInHours < 24 ? time.format('HH:mm A') : time.fromNow();
    };

    return (
        <div>
            <div className='table-order-list'>
                <Table bordered>
                    <thead>
                        <tr className='tr-order-history-table'>
                            <th>Customer</th>
                            <th>Time</th>
                            <th>Status</th>
                            <th>Total Amount</th>
                            <th>Payment Method</th>
                            <th className='order-name-qty-th'>Order</th>
                            <th>Special Instructions</th>
                            <th>Customer Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedOrders.map((order) => (
                            <tr key={order._id}>
                                <td>{order.name}<br /><br /> {order.phoneno}</td>
                                <td>{formatTime(order.createdAt)}</td>
                                <td>
                                    <p className={`order-status ${order.status}`}>
                                        {order.status}
                                    </p>
                                </td>
                                <td>{order.totalAmount}</td>
                                <td>{order.paymentMethod}</td>
                                <td>
                                {order.items.map(item => (
                                        <div className='order-detail-admin-sight' key={item._id}>
                                          <span className='dish-name-order-adminsight'>{item.dishname}</span> <span className='dish-qty-order-adminsight'>{item.quantity}</span>
                                        </div>
                                    ))}
                                </td>
                                <td>{order.specialInstruction}</td>
                                <td><p>{order.location}</p></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Tooltip id="cancelTooltip" />
                <Tooltip id="confirmTooltip" />
            </div>

            <div className='mobile-view'>
                <Row>
                    {sortedOrders.map((order, index) => (
                        <Col md={6} key={index} className='order-column'>
                            <div className='mobile-order-card'>
                                <div className='order-name-time-mobile'>
                                    <div>
                                        <p className='customer-name'>{order.name}</p>
                                        <p className='order-time'>{formatTime(order.createdAt)}</p>
                                    </div>
                                    <div className={`order-status ${order.status}`}>
                                        {order.status}
                                    </div>
                                </div>
                                <hr />
                                <div className='product-details'>
                                    {order.items.map(item => (
                                        <div key={item._id}>
                                            {item.dishname} (x{item.quantity})
                                        </div>
                                    ))}
                                </div>
                                <hr />
                                <div className='order-mobile-pickup-delivery-address-branch'>
                                    <div>
                                        <p className='order-address-branch-mobile'>{order.location}</p>
                                        <p className='order-pickup-delivery-mobile'>{order.specialInstruction}</p>
                                    </div>
                                </div>
                                <div className='amount-btns-order-mobile'>
                                    <div><p>{order.totalAmount}</p></div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                <Tooltip id="cancelTooltip" />
                <Tooltip id="confirmTooltip" />
            </div>
        </div>
    );
};

export default OrderHistoryList;
