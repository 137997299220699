import React from 'react'
import { useKababContext } from '../../../../KababContext';
import { Col, Container, Row } from 'react-bootstrap';
import AdmininfoSearchBar from './AdmininfoSearchBar/AdmininfoSearchBar';
import AdminInfoData from './AdminInfoData/AdminInfoData';

function AdminInfoContent() {
    const { sidebarOpen } = useKababContext();
    const contentStyle =  sidebarOpen ? 'Side-bar-closed' : 'Side-bar-open';
    return (
      <div className={contentStyle}>
       <p className='main-tital-adminpenal'>
        Admin Information
      </p>
      <Container fluid>
        <Row>
          <Col md={12}><AdmininfoSearchBar/></Col>
          <Col md={12}><AdminInfoData/></Col>
        </Row>
      </Container>
      </div>
  )
}

export default AdminInfoContent
 