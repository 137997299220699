import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import SabCategoryCardGraph from '../SabCategoryCardGraph/SabCategoryCardGraph';
import { Link, useNavigate } from 'react-router-dom';
import { useKababContext } from '../../../../../KababContext';

function SabCategory() {
    const {  searchbar } = useKababContext();
    const [data, setData] = useState([]);
    const [sessionsuperadmin, setSessionsuperadmin] = useState(null);
    const navigate = useNavigate();

    // Functiion for Featch Data 
    const fetchApi = async () => {
        try {
            const response = await fetch("https://backend.bkabab.com/showitems");
            if (!response.ok) { throw new Error('Network response was not ok ' + response.statusText); }
            const value = await response.json();
            setData(value);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchApi();
        const userData = JSON.parse(sessionStorage.getItem('user'));
        if (userData && userData.role === 'Super-admin') {
            setSessionsuperadmin(userData.role);
        }
    }, [fetchApi]);

    const handleEdit = (item) => {
        console.log('Edit clicked', item);
        navigate('/adddishes', { state: { cardData: item } });
    };

    // Function For Dlete data 
    var deletedish = async (id) => {
        try {
            var response = await fetch(`https://backend.bkabab.com/deletedishes/${id}`, {
                method: "DELETE"
            });
            if (response.ok) {
                fetchApi();
            } else {
                console.error("Failed to delete Dish-card:", response.statusText);
            }
        } catch (error) {
            console.error("Error deleting Dish-card:", error);
        }
    };

    const inputFieldname = data.filter((item) => 
        (item.dishname.toLowerCase() + ' ' + item.category.toLowerCase()).includes(searchbar.toLowerCase())
    );
    
    return ( 
        <div className='sabcategory-dishes-main'>
            <Row className='sabcategory-dishes'>
                {inputFieldname.map((item) => (
                    <Col sm={12} md={6} xl={4} key={item._id} className='main-coloum-sabcategroy-cards'> 
                        <div className='main-sabcategory-card'>
                            <div className='sabcategory-image'>
                                <img src={`https://backend.bkabab.com/foodimage/${item.dishimage}`} alt={item.dishname} />
                            </div>
                            <div className='sabcategory-titalordata'>
                                <div className='sabcategory-card-titalamount-likes'>
                                    <div className='card-tital-likes-price'>
                                        <div>
                                            <p>{item.dishname}</p>
                                            <p>{item.price}</p>
                                        </div>
                                    </div>
                                    <div className='sabcategroy-card-graph'>
                                        <SabCategoryCardGraph />
                                        <div className='sabcategory-card-edit-main'>
                                            {sessionsuperadmin ? (
                                                <div className='main-btns-sabcategory-editdelte'>
                                                    <Button className='edit-button-sabcategory' onClick={() => handleEdit(item)}>Edit</Button>
                                                    <Link onClick={() => deletedish(item._id)} className='delete-button-sabcategory' to="#">Delete</Link>
                                                </div>
                                            ) : 'Super-admin perform actions'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default SabCategory;
