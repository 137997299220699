import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './UserSight/Screens/Home/Home';
import AdminDashBord from './AdminSight/Screens/Home/DashBord/AdminDashBord';
import Orders from './AdminSight/Screens/Home/Orders/Orders';
import OrderHistory from './AdminSight/Screens/Home/OrderHistory/OrderHistory';
import AllDishes from './AdminSight/Screens/Home/AllDishes/AllDishes';
import AddDishes from './AdminSight/Screens/Home/AddDishes/AddDishes';
import UserInfo from './AdminSight/Screens/Home/UserInfo/UserInfo';
import AdminInfo from './AdminSight/Screens/Home/AdminInfo/AdminInfo';
import NewAdmin from './AdminSight/Screens/Home/NewAdmin/NewAdmin';
import NewUser from './AdminSight/Screens/Home/NewUser/NewUser';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BuyNow from './UserSight/Screens/BuyNow/BuyNow';
import KababHooramenu from './UserSight/Screens/Kababmenu/KababHooramenu';
import KababRiffamenu from './UserSight/Screens/Kababmenu/KababRiffamenu';

function App() {
  return (
    <Router>
      <ToastContainer />
      <Routes>
        {/* Routing For User Sight */}
        <Route path='/' element={<Home />} />
        <Route path='/ordernow' element={<BuyNow/>} />
        <Route path='/HooraMenu' element={<KababHooramenu/>} />
        <Route path='/RiffaMenu' element={<KababRiffamenu/>} />

        {/* Routing For Admin Sight */}
        <Route path='/dashboard' element={<AdminDashBord />} />
        <Route path='/orders' element={<Orders />} />
        <Route path='/history' element={<OrderHistory />} />
        <Route path='/alldishes' element={<AllDishes />} />
        <Route path='/adddishes' element={<AddDishes />} />
        <Route path='/userinfo' element={<UserInfo />} />
        <Route path='/adduser' element={<NewUser />} />
        <Route path='/admininfo' element={<AdminInfo />} />
        <Route path='/addadmin' element={<NewAdmin />} />

      </Routes>
    </Router>
  )
}

export default App
