import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SearchBarAdminPenal from '../../../SearchBarAdminPenal/SearchBarAdminPenal';
import { Link } from 'react-router-dom';

function DishesSearchBar() {
    return (
        <div>
            <Container fluid>
                <Row className='row-main-dishes-searchbar'>
                    <Col md={6} sm={12} className='col-main-search-bar-dishes'><SearchBarAdminPenal /></Col>
                    <Col md={3} sm={4} className='col-main-link-dishes'>
                            <Link className='link-dishes-search-bar' to="/adddishes">Add Dishes</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default DishesSearchBar
