import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function Card4() {
  const [totalUsers, setTotalUsers] = useState(0);

  // Function to calculate the percentage dynamically
  const calculatePercentage = (users) => {
    if (users < 100) {
      return (users / 100) * 100; // Percentage out of 100
    } else if (users < 1000) {
      return (users / 1000) * 100; // Percentage out of 1000
    } else if (users < 10000) {
      return (users / 10000) * 100; // Percentage out of 10000
    }else if (users < 100000) {
      return (users / 100000) * 100; // Percentage out of 10000
    }else if (users < 1000000) {
      return (users / 1000000) * 100; // Percentage out of 10000
    }
    // You can continue adding more ranges as needed
    return 100; // Cap at 100% for anything beyond 10000
  };

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const response = await fetch('https://backend.bkabab.com/totalusers');
        const data = await response.json();
        setTotalUsers(data.totalUsers);
      } catch (error) {
        console.error('Error fetching total users:', error);
      }
    };

    fetchTotalUsers();

    // Set up interval to fetch data every 1 seconds
    const intervalId = setInterval(() => {
      fetchTotalUsers();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Calculate the percentage for the circular progress bar
  const percentage = calculatePercentage(totalUsers);

  return (
    <div className='card-main-body-dashbord'>
      <Row>
        <Col>
          <p className='adminpenal-card-tital'>Total Customers</p>
          <p className='adminpenal-card-value'>{totalUsers}</p>
        </Col>
        <Col className='progressCol-dashbord-cards'>
          <div className='progressContainer-main-container-dashbord'>
            <CircularProgressbar
              value={percentage}
              styles={buildStyles({
                pathColor: `#006e6b`,
              })}
            />
            <i className="las la-user-alt icons-cards-dashbord"></i>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Card4;
