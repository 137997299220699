import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ModelAddCategory({ show, handleClose }) {
    const [error, setError] = useState('');
    const [category, setCategory] = useState('');

    const handleInput = (e) => {
        setCategory(e.target.value);
    };

    
    const handleFormData = async (e) => {
        e.preventDefault();
    
        try {
            if (!category) {
                setError('Category is required.');
                return;
            }
    
            const response = await fetch('https://backend.bkabab.com/addcategory', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: category }),
            });
    
            const data = await response.json();
    
            if (response.status === 400 && data.error === 'This category already exists') {
                setError('This category is already added.');
            } else if (!response.ok) {
                setError(`Failed to create category: ${data.error || response.statusText}`);
            } else {
                setCategory('');
                setError('');
                handleClose();
            }
        } catch (error) {
            console.error('Error creating category:', error.message);
            setError('An unexpected error occurred.');
        }
    };
    
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
            <Modal.Header>
                <Modal.Title className='model-main'>
                    <img src='BkababLogo.jpg' width={70} alt='Bahrain Kebabs Logo' />
                    <p className='mode-tital'>Add New Category</p>
                </Modal.Title>
                <Link variant="secondary" className='close-link-model' onClick={handleClose}>
                    X
                </Link>
            </Modal.Header>
            <Modal.Body>
                <div className='model-Addcategory'>
                    <input name='name' type="text" value={category.name} onChange={handleInput} placeholder="Enter New Category name..." className='model-search-bar'/>
                </div>
                <p className='error-model-category'>{error}</p>
                <div className='model-Addcategory'> 
                    <a href='#' onClick={handleFormData} className='model-link-save-addcategory'>
                        Save
                    </a>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='models-footer-main'>
                    <div className='copy-right-text-bottom-footer'>
                        <p>Copyright © 2024, All Right Reserved. Powered By </p> &nbsp; &nbsp;<Link className='bottom-footer-links'> SGB WORLD </Link>&nbsp;&nbsp;
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModelAddCategory;
