import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const SoldGraph = () => {
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Data',
        data: [3, 4, 2, 5, 6, 8],
        fill: 'start', // Gradient fill
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        tension: 0.4,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0, // Hide points
      },
    },
  };

  return (
    <div className='card-graph-small'>
      <Line data={data} options={options} />
    </div>
  );
};

export default SoldGraph;
