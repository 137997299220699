import React, { useEffect, useState } from 'react'
import Stack from 'react-bootstrap/Stack';
import { Link } from 'react-router-dom';
import LoginModel from './Login/LoginModel';
import SignUpModel from './SignUp/SignUpModel';
import { useKababContext } from '../../../KababContext';
import Dropdown from 'react-bootstrap/Dropdown';

function HeaderMenu() {
  const { handleShowLoginModel, showLoginModel, handleCloseLoginModel, handleShowSignupModel, showSignupModal, handleCloseSignupModel, user, fetchUserData } = useKababContext();

  useEffect(() => {
    fetchUserData();
    const intervalId = setInterval(() => {
      fetchUserData();
  }, 50); 
  return () => clearInterval(intervalId);
  }, []);



  return (
    <div>
      <div className='header-links'>
        <Stack direction="horizontal" className='main-menu-stack'>
          <div className='home-menu-links'>
            <Link to="/" className='Links-main-header'>Home</Link>
            <Dropdown>
              <Dropdown.Toggle as={Link} className='Links-main-header'>
                Menu
              </Dropdown.Toggle>
              <Dropdown.Menu className='dropdwon-language'>
                <Link className="menubranches-link" to="/HooraMenu">Hoora Branch</Link>
                <hr />
                <Link className="menubranches-link" to="/RiffaMenu">Riffa Branch</Link>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {!user ? (
            <div>
              <Link onClick={handleShowLoginModel} className='Links-main-header'>Login</Link>
              &nbsp; <strong>/</strong> &nbsp;
              <Link onClick={handleShowSignupModel} className='Links-main-header'>Sign-up</Link>
            </div>
          ) : ''}
        </Stack>
      </div>
      <LoginModel show={showLoginModel} handleClose={handleCloseLoginModel} handleShowSignupModel={handleShowSignupModel} />
      <SignUpModel show={showSignupModal} handleClose={handleCloseSignupModel} handleShowLoginModel={handleShowLoginModel} />
    </div>
  )
}

export default HeaderMenu;