import React, { useEffect, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function CategoryDishes() {
    const [data, setData] = useState([]);
    const [sessionsuperadmin, setSessionsuperadmin] = useState(null);

    // function for featching data 
    const fetchApi = async () => {
        try {
            const response = await fetch("https://backend.bkabab.com/categories");
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            const value = await response.json();
            setData(value);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
      fetchApi();
      const userData = JSON.parse(sessionStorage.getItem('user'));
      if (userData && userData.role === 'Super-admin') {
          setSessionsuperadmin(userData.role);
      }
      const intervalId = setInterval(() => {
        fetchApi();
    }, 1000); 
    return () => clearInterval(intervalId);
  }, []);
    // Function for delete data
    var deleteCategory = async (id) => {
        try {
          var response = await fetch(`https://backend.bkabab.com/deletecategory/${id}`, {
            method: "DELETE"
          });
          if (response.ok) {
            fetchApi(); // Ensure this function is defined and working properly
          } else {
            console.error("Failed to delete category:", response.statusText);
          }
        } catch (error) {
          console.error("Error deleting category:", error);
        }
      };
      
    return (
        <div className="category-slider">
        <p className='category-main-dishes-title'>Category</p>
        <Row className='row-main-category-dishes'>
            {data.map((item) => (
                <Col key={item._id} xs={12} sm={6} md={3} lg={4} xl={3} xxl={2} className='col-main-dishes-category'>
                    <div className='category-text-main-dishes'>
                        <p>{item.name}</p>
                    </div>
                    {sessionsuperadmin ? (
                    <Link onClick={() => deleteCategory(item._id)} className='category-delete-icon'><i class="las la-trash"></i></Link>
                  ) : ''}
                </Col>
            ))}
        </Row>
    </div>
    
    );
}

export default CategoryDishes;
