import React, { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import { Link, useNavigate } from 'react-router-dom';
import { useKababContext } from '../../../KababContext';
import ModelAddress from '../../Screens/Home/Session-2/ModelAddress';
import ModelPickup from '../../Screens/Home/Session-2/ModelPickup';
import { toast } from 'react-toastify';

function AddtoCart() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { increaseQty, decreaseQty, cartItems, setCartItems, handleShowAddressModal, handleCloseAddressModal, handleShowPickupModal, handleClosePickupModal, showAddressModal, showPickupModal } = useKababContext();
    const navigate = useNavigate();

    useEffect(() => {
        // Optional: Sync dropdown state with cartItems
        if (cartItems.length === 0) {
            setDropdownOpen(false);
        }
    }, [cartItems]);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const clearCart = () => {
        setCartItems([]);
        sessionStorage.removeItem('cartItems');
        toast.info('All items removed from cart', { position: "top-right", autoClose: 1000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light" });
    };

    const handleOrderNow = () => {
        const selectedLocation = sessionStorage.getItem('selectedLocation');
        if (!selectedLocation) {
            toast.error('Please select your address', { position: "top-right", autoClose: 1000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light" });
            handleShowAddressModal();
            return;
        } else {
            navigate('/ordernow');
        }
    };

    return (
        <div className="add-to-cart-container">
            <Nav>
                <Link onClick={toggleDropdown} className="cart-icon-container">
                    <i className="las la-shopping-cart"></i>
                    {cartItems.length > 0 && (
                        <p className="cart-count">{cartItems.length}</p>
                    )}
                </Link>
            </Nav>

            {dropdownOpen && (
                <div className="custom-dropdown-menu">
                    {cartItems.length > 0 ? (
                        <>
                            {cartItems.map((item, index) => (
                                <div key={index}>
                                    <div className="dropdown-item">
                                        <p>{item.dishname}</p>
                                        <p>{(item.price * item.quantity).toFixed(2)} $</p>
                                        <div className="quantity-controls">
                                            <button className='button-minus' onClick={() => decreaseQty(index)}>-</button>
                                            <span>{item.quantity}</span>
                                            <button className='button-plus' onClick={() => increaseQty(index)}>+</button>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            ))}
                            <div className="dropdown-actions">
                                <button className="clear-btn" onClick={clearCart}>Clear all</button>
                                <button className="order-btn" onClick={handleOrderNow}>Order Now</button>
                            </div>
                        </>
                    ) : (
                        <div className="dropdown-item">
                            <p>No items in the cart</p>
                        </div>
                    )}
                </div>
            )}

            <ModelAddress show={showAddressModal} handleClose={handleCloseAddressModal} handleShowPickup={handleShowPickupModal} />
            <ModelPickup show={showPickupModal} handleClose={handleClosePickupModal} handleShowAddress={handleShowAddressModal} />
        </div>
    );
}

export default AddtoCart;
