import React from 'react'
import { useKababContext } from '../../../../KababContext';
import {Col, Container, Row} from 'react-bootstrap';
import FormAddnewadminuser from './FormAddnewadmin/FormAddnewadmin';

function NewAdminContent() {
  const { sidebarOpen } = useKababContext();
  const contentStyle = sidebarOpen ? 'Side-bar-closed' : 'Side-bar-open';
  return (
    <div className={contentStyle}>
      <p className='main-tital-adminpenal'>
        Add Admin 
      </p>
      <Container fluid>
        <Row>
          <Col md={12}><FormAddnewadminuser/></Col>
        </Row>
      </Container>
    </div>
  )
}

export default NewAdminContent
