// ModelPickup.jsx
import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useKababContext } from '../../../../KababContext';

function ModelPickup({ show, handleClose, handleShowAddress }) {
    const { handleSaveSelection } = useKababContext();
    const [selectedPickupBranch, setSelectedPickupBranch] = useState('');

    const handleBranchChange = (event) => {
        setSelectedPickupBranch(event.target.value);
    };

    const handleSave = () => {
        handleSaveSelection('pickupBranch', selectedPickupBranch);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
            <Modal.Header>
                <Modal.Title className='model-main'>
                    <img src='BkababLogo.jpg' width={70} alt='Bahrain Kebabs Logo' />
                    <p className='mode-tital'>Select your Pick Up Branch</p>
                </Modal.Title>
                <Link variant="secondary" className='close-link-model' onClick={handleClose}>
                    X
                </Link>
            </Modal.Header>
            <Modal.Body>
                <div className='model1-main-body'>
                    <div className='model1-second'>
                        <Link onClick={handleShowAddress} className='deleviry-model-2-btn-1'>Delivery</Link>
                        <Link className='deleviry-model-2-btn-2'>Pick Up</Link>
                    </div>
                </div>
                <div className='model-search-bar-main'>
                    <Form.Select aria-label="Default select example" onChange={handleBranchChange} value={selectedPickupBranch}>
                        <option value="">Select a Branch</option>
                        <option value="KABAB HOUSE HOORA">KABAB HOUSE HOORA</option>
                        <option value="KABAB HOUSE RIFFA">KABAB HOUSE RIFFA</option>
                    </Form.Select>
                </div>
                <div className='model-search-bar-main'>
                    <Link className='model-link-save' onClick={handleSave}>
                        Save
                    </Link>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='models-footer-main'>
                <div className='copy-right-text-bottom-footer'>
                    <p>Copyright © 2024, All Right Reserved. Powered By </p> &nbsp; &nbsp;<Link className='bottom-footer-links'> SGB WORLD </Link>&nbsp;&nbsp;
                </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModelPickup;
