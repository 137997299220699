import React from 'react'
import { useKababContext } from '../../../../KababContext'

function SearchBarComponent() {
    const {  handleSearch } = useKababContext();
    return (
        <div>

            <div className='searchbar-main-session-3'>
                <input onChange={handleSearch} className='searchbar-session-3' placeholder='Search . . . . . ' />
            </div>
        </div>
    )
}

export default SearchBarComponent
