import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function UserFormedit({ show, handleClose, onSave }) {
    const [userData, setUserData] = useState({
        name: '',
        phoneno: '',
        email: '',
        role: 'user'  // Automatically set the role as 'user'
    });
    const [errors, setErrors] = useState({}); // State to hold validation errors

    useEffect(() => {
        const storedUserData = sessionStorage.getItem('user');
        if (storedUserData) {
            try {
                const parsedUserData = JSON.parse(storedUserData);
                setUserData({
                    name: parsedUserData.name || '',
                    phoneno: parsedUserData.phoneno || '',
                    email: parsedUserData.email || '',
                    role: 'user',  // Always set role as 'user'
                });
            } catch (error) {
                console.error('Error parsing session storage data:', error);
            }
        }
    }, []);

    const validate = () => {
        let validationErrors = {};
        if (!userData.name) {
            validationErrors.name = "Name is required";
        }
        if (!userData.email) {
            validationErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
            validationErrors.email = "Invalid email address";
        }
        if (!userData.phoneno) {
            validationErrors.phoneno = "Phone number is required";
        } else if (userData.phoneno.length < 10 || userData.phoneno.length > 12) {
            validationErrors.phoneno = "Phone number must be 11 digits";
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name !== 'name') { // Prevent changing the 'name' field
            setUserData({ ...userData, [name]: value });
        }
    };
    const handleSave = () => {
        if (validate()) {
            sessionStorage.setItem('user', JSON.stringify(userData));  // Save updated data including role
            onSave(userData);  // Notify parent component of the update
            handleClose(); 
        }
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
            <Modal.Header>
                <Modal.Title className='model-main'>
                    <img src='BkababLogo.jpg' width={70} alt='Bahrain Kebabs Logo' />
                    <p className='mode-tital'>Edit Your Details</p>
                </Modal.Title>
                <Link variant="secondary" className='close-link-model' onClick={handleClose}>
                    X
                </Link>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form.Group className='mt-3'>
                        <Form.Control 
                            type="text" 
                            placeholder="Name *" 
                            name="name" 
                            value={userData.name} 
                            onChange={handleInputChange} 
                            isInvalid={!!errors.name} 
                            readOnly // Set to readOnly
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.name}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mt-3'>
                        <Form.Control 
                            type="email" 
                            placeholder="E-mail *" 
                            name="email" 
                            value={userData.email} 
                            onChange={handleInputChange} 
                            isInvalid={!!errors.email} 
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mt-3'>
                        <Form.Control 
                            type="number" 
                            placeholder="Phone No. *" 
                            name="phoneno" 
                            value={userData.phoneno} 
                            onChange={handleInputChange} 
                            isInvalid={!!errors.phoneno} 
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.phoneno}
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='model-search-bar-main-usereditmodel'>
                    <Link className='model-link-save' onClick={handleSave}>
                        Save
                    </Link>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='models-footer-main'>
                    <div className='copy-right-text-bottom-footer'>
                        <p>Copyright © 2024, All Right Reserved. Powered By </p> &nbsp; &nbsp;<Link className='bottom-footer-links'> SGB WORLD </Link>&nbsp;&nbsp;
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default UserFormedit;
