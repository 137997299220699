import React from 'react'
import { useKababContext } from '../../../../KababContext';
import AddDishForm from './AddDishForm/AddDishForm';

function AddDishesContent() {
  const { sidebarOpen } = useKababContext();
  const contentStyle = sidebarOpen ? 'Side-bar-closed' : 'Side-bar-open';
  return (
    <div className={contentStyle}>
      <p className='main-tital-adminpenal'>
        Add New Dishe !
      </p>
      <div className='content-component-adddishes'>
        <AddDishForm/>
      </div>
    </div>
  )
}

export default AddDishesContent
