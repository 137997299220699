import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';

function Coursel() {
    return (
        <div>
            <Carousel indicators={false} interval={3000}>
                <Carousel.Item>
                    <div className='courselimage-1 img-fluid'>
                        <div className='blur-background-courselimage-1'>
                        </div>
                        <div className='coursel-Text'>
                            <p className='coursel-2text-1'>Bahrain Kabab House</p>
                            <p className='coursel-2text-2'>Experience the Flavor</p>
                            <div className='button-coursel'><Link className='btn-main-coursel' to="/about">More InFo</Link></div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='courselimage-2'>
                    <div className='blur-background-courselimage-2'>
                    </div>
                        <div className='coursel-Text'>
                            <p className='coursel-2text-1'>Bahrain Kabab House</p>
                            <p className='coursel-2text-2'>Fresh Ingredients</p>
                            <div className='button-coursel'><Link className='btn-main-coursel' to="/about">More InFo</Link></div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='courselimage-3'>
                    <div className='blur-background-courselimage-3'>
                    </div>
                        <div className='coursel-Text'>
                            <p className='coursel-2text-1'>Bahrain Kabab House</p>
                            <p className='coursel-2text-2'>Grilled to Perfection</p>
                            <div className='button-coursel'><Link className='btn-main-coursel' to="/about">More InFo</Link></div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='courselimage-4'>
                    <div className='blur-background-courselimage-4'>
                    </div>
                        <div className='coursel-Text'>
                            <p className='coursel-2text-1'>Bahrain Kabab House</p>
                            <p className='coursel-2text-2'>Delicious Bahrain Kebabs</p>
                            <div className='button-coursel'><Link className='btn-main-coursel' to="/about">More InFo</Link></div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='courselimage-5'>
                    <div className='blur-background-courselimage-5'>
                    </div>
                        <div className='coursel-Text'>
                            <p className='coursel-2text-1'>Bahrain Kabab House</p>
                            <p className='coursel-2text-2'>Join Us for a Meal</p>
                            <div className='button-coursel'><Link className='btn-main-coursel' to="/about">More InFo</Link></div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='courselimage-6'>
                    <div className='blur-background-courselimage-6'>
                    </div>
                        <div className='coursel-Text'>
                            <p className='coursel-2text-1'>Bahrain Kabab House</p>
                            <p className='coursel-2text-2'>Authentic Taste of Bahrain</p>
                            <div className='button-coursel'><Link className='btn-main-coursel' to="/about">More InFo</Link></div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default Coursel;
