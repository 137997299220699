import React, { useEffect, useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useKababContext } from '../../../../KababContext';
import LoginModel from '../../../Components/Header/Login/LoginModel';
import SignUpModel from '../../../Components/Header/SignUp/SignUpModel';

function CardsComponents({ onCategoryInView }) {
    const { searchbar, addToCart, setUser, handleShowLoginModel, showLoginModel, handleCloseLoginModel, handleShowSignupModel, showSignupModal, handleCloseSignupModel } = useKababContext();
    const [data, setData] = useState([]);
    const [groupedData, setGroupedData] = useState({});

    // Refs for category sections
    const categoryRefs = useRef({});

    // Fetch Data Function
    const fetchApi = async () => {
        try {
            const response = await fetch("https://backend.bkabab.com/showitems");
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            const value = await response.json();

            // Group data by category
            const grouped = value.reduce((acc, item) => {
                const category = item.category;
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push(item);
                return acc;
            }, {});
            setGroupedData(grouped);
            setData(value);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchApi();
        const userData = JSON.parse(sessionStorage.getItem('user'));
        if (userData && userData.role === 'user') {
            setUser(userData.role);
        }
        const intervalId = setInterval(() => {
            fetchApi();
        }, 500); 
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        onCategoryInView(entry.target.id);
                    }
                });
            },
            { threshold: 0.7 }
        );
        Object.values(categoryRefs.current).forEach(ref => {
            if (ref) observer.observe(ref);
        });
        return () => {
            Object.values(categoryRefs.current).forEach(ref => {
                if (ref) observer.unobserve(ref); 
            });
        };
    }, [groupedData]);
    const filteredCategories = Object.keys(groupedData).filter((category) =>
        groupedData[category].some((item) =>
            (item.dishname.toLowerCase() + ' ' + item.category.toLowerCase()).includes(searchbar.toLowerCase())
        )
    );

    return (
        <div>
            {filteredCategories.map((category) => (
                <div key={category} id={category} ref={el => (categoryRefs.current[category] = el)}>
                    <p className='category-cards-tital'>{category}</p>
                    <Row>
                        {groupedData[category].map((item) => (
                            <Col key={item._id} sm={12} md={6} xxl={4} className='col-main-session-3-card'>
                                <div className='card-first-hiogh-screen'>
                                    <div>
                                        <img src={`https://backend.bkabab.com/foodimage/${item.dishimage}`} className='image-fluid image-card-session-3' />
                                    </div>
                                    <div className='card-btn-tital-text-first'>
                                        <div className='session-3-card-text-tital'>
                                            <h6>{item.dishname}</h6>
                                            <p className='user-sight-card-price'>{item.price}</p>
                                            <p className='user-sight-card-detail'> {item.detail}</p>
                                        </div>
                                            <div className='session-3-links'>
                                                <Link className='sesion-3-links-add' onClick={() => addToCart(item)}>
                                                    <i className="las la-cart-plus"></i>
                                                </Link>
                                            </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}
            <LoginModel show={showLoginModel} handleClose={handleCloseLoginModel} handleShowSignupModel={handleShowSignupModel} />
            <SignUpModel show={showSignupModal} handleClose={handleCloseSignupModel} handleShowLoginModel={handleShowLoginModel} />
        </div>
    );
}

export default CardsComponents;
