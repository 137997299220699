import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SearchBarAdminPenal from '../../../SearchBarAdminPenal/SearchBarAdminPenal'

function UserinfoSearchBar() {
  return (
    <div>
    <Container fluid>
        <Row className='row-main-dishes-searchbar'>
            <Col md={6} sm={12} className='col-main-search-bar-dishes'><SearchBarAdminPenal /></Col>
            <Col md={3} sm={4} className='col-main-link-dishes'>
                            <Link className='link-dishes-search-bar' to="/adduser">Add New User</Link>
                    </Col>
        </Row> 
    </Container>
</div>
  )
}

export default UserinfoSearchBar
