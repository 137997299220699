import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function Card3() {
  const [totalCategories, setTotalCategories] = useState(0);

  // Function to calculate the percentage dynamically
  const calculatePercentage = (categories) => {
    if (categories < 100) {
      return categories; // Direct percentage if less than 100
    } else if (categories < 1000) {
      return (categories / 1000) * 100; // Percentage out of 1000
    } else if (categories < 10000) {
      return (categories / 10000) * 100; // Percentage out of 10000
    }else if (categories < 100000) {
      return (categories / 100000) * 100; // Percentage out of 10000
    }else if (categories < 1000000) {
      return (categories / 1000000) * 100; // Percentage out of 10000
    }
    
    // You can continue adding more ranges as needed
    return 100; // Cap at 100% for anything beyond 10000
  };

  useEffect(() => {
    const fetchTotalCategories = async () => {
      try {
        const response = await fetch('https://backend.bkabab.com/totalcategories');
        const data = await response.json();
        setTotalCategories(data.totalCategories);
      } catch (error) {
        console.error('Error fetching total categories:', error);
      }
    };

    fetchTotalCategories();

    // Set up interval to fetch data every 1 seconds
    const intervalId = setInterval(() => {
      fetchTotalCategories();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Calculate the percentage for the circular progress bar
  const percentage = calculatePercentage(totalCategories);

  return (
    <div className='card-main-body-dashbord'>
      <Row>
        <Col>
          <p className='adminpenal-card-tital'>Total Categories</p>
          <p className='adminpenal-card-value'>{totalCategories}</p>
        </Col>
        <Col className='progressCol-dashbord-cards'>
          <div className='progressContainer-main-container-dashbord'>
            <CircularProgressbar
              value={percentage}
              styles={buildStyles({
                pathColor: `#006e6b`,
              })}
            />
            <i className="las la-clipboard icons-cards-dashbord"></i>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Card3;
