import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Col, Row } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { Link, useNavigate } from 'react-router-dom';
import { useKababContext } from '../../../../../KababContext';
import { toast } from 'react-toastify';

function UserInfoData() {
    const {  searchbar } = useKababContext();
    const [data, setData] = useState([]); 
    const navigate = useNavigate();
    const [sessionsuperadmin, setSessionsuperadmin] = useState(null);

    // Fetch data function
    const fetchApi = async () => {
        try {
            const response = await fetch("https://backend.bkabab.com/showuser");
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            const value = await response.json();
            setData(value);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Fetch user data on component mount
    useEffect(() => {
        fetchApi();
        const userData = JSON.parse(sessionStorage.getItem('user'));
        if (userData && userData.role === 'Super-admin') {
            setSessionsuperadmin(userData.role);
        }
    }, [fetchApi]);

    const handleEdit = (item) => {
        console.log('Edit clicked', item);
        navigate('/adduser', { state: { userData: item } });
    };
    // Delete user function
    const deleteuser = async (id) => {
        try {
            const response = await fetch(`https://backend.bkabab.com/deleteuser/${id}`, {
                method: "DELETE"
            });
            if (response.ok) {
                fetchApi();
                toast.info('User Delete Successfully', {position: "top-right", autoClose: 500, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", });
            } else {
                console.error("Failed to delete User:", response.statusText);
            }
        } catch (error) {
            console.error("Error deleting User:", error);
        }
    };
   // Show acurtte date 
   const formatDate = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options).replace(',', ' -');
};
    
    const inputFieldname = data.filter((item) => 
        (item.name.toLowerCase() + ' ' + item.phoneno.toLowerCase()).includes(searchbar.toLowerCase())
    );
    
    return ( 
        <div>
            <div className='table-order-list'>
                <Table bordered>
                    <thead>
                        <tr className='tr-order-history-table'>
                            <th>Names</th>
                            <th>Phone No.</th>
                            <th>E-mail</th>
                            <th>Last Login</th>
                            <th className='th-accountcreationdate-userinfo'>Account Creation Date</th>
                            <th className='th-action-userinfo'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {inputFieldname.map((item) => (
                            <tr key={item._id}>
                                <td>{item.name} </td>
                                <td>{item.phoneno}</td>
                                <td>{item.email}</td>
                                <td>{formatDate(item.lastLogin)}</td>
                                <td>{formatDate(item.accountcreatedate)}</td>
                                <td className='action-btns-order-list'>
                                    {sessionsuperadmin ? (
                                    <div className='action-btn-mobile-screen-order-list'>
                                        <Link onClick={() => deleteuser(item._id)} className='order-action-links-crose' data-tooltip-id="cancelTooltip" data-tooltip-content="Delete">
                                            <i className="las la-trash"></i>
                                        </Link>
                                        <button onClick={() => handleEdit(item)} className='order-action-links-tick' data-tooltip-id="confirmTooltip" data-tooltip-content="Edit">
                                            <i className="las la-edit"></i>
                                        </button>
                                    </div>
                                    ) : 'Super-admin perform actions'}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Tooltip id="cancelTooltip" />
                <Tooltip id="confirmTooltip" />
            </div>

            {/* Mobile-friendly view */}
            <div className='mobile-view'>
                <Row>
                    {inputFieldname.map((item) => (
                        <Col md={6} key={item._id} className='order-column'>
                            <div className='mobile-order-card'>
                                <div className='order-name-time-mobile'>
                                    <div>
                                        <p className='customer-name'>{item.firstName} {item.lastName}</p>
                                        <p className='order-time'>{formatDate(item.lastLogin)}</p>
                                    </div>
                                </div>
                                <div className='total-order-container'>
                                </div>
                                <div className='order-monile-pickupdelevery-addressbranch'>
                                    <div>
                                        <p className='order-pickup-delivery-mobile'>{item.phoneno}</p>
                                        <p className='order-address-branch-mobile'>{item.email}</p>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <p>{formatDate(item.accountcreatedate)}</p>
                                </div>
                                
                                <div className='amount-btns-userinfo-mobile'>
                                    <div className='action-btn-mobile-screen-userinfo'>
                                        {sessionsuperadmin ? (
                                        <div>
                                            <Link onClick={() => deleteuser(item._id)} className='order-action-links-crose' data-tooltip-id="cancelTooltip" data-tooltip-content="Delete">
                                                <i className="las la-trash"></i>
                                            </Link>
                                            <button  onClick={() => handleEdit(item)}  className='order-action-links-tick' data-tooltip-id="confirmTooltip" data-tooltip-content="Edit">
                                                <i className="las la-edit"></i>
                                            </button>
                                        </div>
                                        ) : 'Super-admin perform actions'}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                <Tooltip id="cancelTooltip" />
                <Tooltip id="confirmTooltip" />
            </div>
        </div>
    );
}

export default UserInfoData;
