import React, { useState ,useEffect } from 'react'
import Header from '../../../Components/Header/Header'
import AsideMenu from '../../AdminAsideMenu/AsideMenu'
import UserInfoContent from './UserInfoContent'
import { useNavigate } from 'react-router-dom';

function UserInfo() {
  const [sessiondata, setSessiondata] = useState({ email: null, role: null });
  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem('user'));
    if (userData && userData.email && (userData.role === 'Super-admin' || userData.role === 'Sab-admin')) {
      setSessiondata(userData);
    } else {
      console.log('Invalid session or role, redirecting to login');
      navigate('/');
    }
  }, [navigate]);
  return (
    <div>
      <Header/>
      <AsideMenu/>
        <div className='content-main'>
      <UserInfoContent/>
      </div>
    </div>
  )
}

export default UserInfo
