import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

function TopHeader() {
  const handleLanguageChange = (lang) => {
    const translateElement = document.querySelector('.goog-te-combo');
    if (translateElement) {
      translateElement.value = lang;
      const event = new Event('change', { bubbles: true });
      translateElement.dispatchEvent(event);
    }
  };

  return (
    <div className='topheader'>
      <Container className='topheader-container'>
        <div className='d-flex'>
          <div className='topheader-social-icons'>
            <h4>Follow us:</h4>
          </div>
          <div className='links-main-Topheader'>
            <div className="link-container">
              <Link to="" className="custom-link"><i className="lab la-facebook"></i></Link>
              <Link to="" className="custom-link"><i className="lab la-youtube"></i></Link>
              <Link to="" className="custom-link"><i className="lab la-instagram"></i></Link>
              <Link to="" className="custom-link"><i className="lar la-envelope"></i></Link>
              <Link to="" className="custom-link"><i className="las la-map-marked"></i></Link>
            </div>
          </div>
        </div>
        <div>
          <Dropdown>
            <Dropdown.Toggle as={Link} className="custom-link">
              <i className="las la-language"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu className='dropdwon-language'>
              <Dropdown.Item onClick={() => handleLanguageChange('en')}>English</Dropdown.Item>
              <Dropdown.Item onClick={() => handleLanguageChange('ar')}>Arabic</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Container>
    </div>
  );
}

export default TopHeader;
