import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CategoryComponent from './CategoryComponent';
import SearchBarComponent from './SearchBarComponent';
import CardsComponents from './CardsComponents';

function CategoryCardSearchBar() {
    const [activeCategory, setActiveCategory] = useState('');

    return (
        <div className='session3-main'>
            <Container fluid className='session-3-container-main'>
                <Row>
                    <Col md={3} className='col-session-3'>
                        <CategoryComponent activeCategory={activeCategory} scrollToCategory={setActiveCategory} />
                    </Col>
                    <Col md={9}>
                        <SearchBarComponent />
                        <CardsComponents onCategoryInView={setActiveCategory} scrollToCategory={(category) => setActiveCategory(category)} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}


export default CategoryCardSearchBar;
