import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ReceiptModal = ({ show, onHide, order, handlePrint }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                    <div className='header-recipt-admin-sight-main'>
                        <div className='header-recipt-admin-sight'>
                        <img src='./BkababLogo.jpg' className='recipt-logo' alt="Bkabab Logo" />
                        <h4>Order Recipt</h4>
                        </div>
                    </div>
                    
            </Modal.Header>
            <Modal.Body>
                <div className='main-detail-recipt-order-admin-sight'>
                    <div className='main-detail-recipt-order' id="receipt-content">
                        <div>
                            <span className='admin-sight-recipt-detail'><p className='tital-recipt-detail-adminsight'>Name: </p><p className='detail-order-recipt-adminsight'>{order.name}</p></span>
                            <span className='admin-sight-recipt-detail'><p className='tital-recipt-detail-adminsight'>Number: </p><p className='detail-order-recipt-adminsight'>{order.phoneno}</p></span>
                            <span className='admin-sight-recipt-detail'><p className='tital-recipt-detail-adminsight'>Location: </p><p className='detail-order-recipt-adminsight'>{order.location}</p></span>
                            <span className='admin-sight-recipt-detail'><p className='tital-recipt-detail-adminsight'>Payment Method:</p><p className='detail-order-recipt-adminsight'>{order.paymentMethod}</p></span>
                            <span className='admin-sight-recipt-detail'><p className='tital-recipt-detail-adminsight'>Special Instructions:</p><p className='detail-order-recipt-adminsight'>{order.specialInstruction}</p></span>
                        </div>
                        <hr />
                        <table>
                            <thead>
                                <tr className='recipt-tr-order-detail'>
                                    <th>QTY</th>
                                    <th>Dish name</th>
                                    <th>Sub Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {order.items.map(item => (
                                    <tr className='recipt-tr-order-detail' key={item._id}>
                                        <td>{item.quantity}</td>
                                        <td>{item.dishname}</td>
                                        <td>{item.totalAmount.toFixed(2)}</td> 
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <hr />
                        <h4>Total Amount: {order.totalAmount} </h4>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="no-print">
                <Button variant="primary" onClick={() => window.print()}>
                    Print
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReceiptModal;
