import React, { createContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const KababContext = createContext();

export const KababProvider = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [searchbar, setSearchbar] = useState('');
    const [cartItems, setCartItems] = useState([]);
    const [user, setUser] = useState(null);
    const [showLoginModel, setShowLoginModel] = useState(false);
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [showPickupModal, setShowPickupModal] = useState(false);

    // Load selected location from sessionStorage
    useEffect(() => {
        const storedLocation = sessionStorage.getItem('selectedLocation');
        if (storedLocation) {
            setSelectedLocation(storedLocation);
        }
    }, []);

    const handleSaveSelection = (key, value) => {
        let combinedValue = value;
    
        // Determine if the selection is for Pickup or Delivery
        const isPickup = key === 'pickupBranch';
        const isDelivery = key === 'location';
    
        if (isPickup || isDelivery) {
            // Combine the location or branch with the service type
            combinedValue = `${value} - ${isPickup ? 'PickUp' : 'Delivery'}`;
            setSelectedLocation(combinedValue);
        } else {
            setSelectedLocation(value);
        }
    
        sessionStorage.setItem('selectedLocation', combinedValue);
    };
    

    // Manage sidebar state
    const isMobileScreen = () => {
        return window.innerWidth <= 500;
    };
    const toggleSidebar = () => {
        if (!isMobileScreen()) {
            setSidebarOpen(!sidebarOpen);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (isMobileScreen()) {
                setSidebarOpen(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // function to heandle search bar
    const handleSearch = (e) => {
        const { value } = e.target;
        setSearchbar(value);
    };

    // Function to handle adding items to the cart
    const addToCart = (item) => {
        if (!user) {
            toast.error('Plese login First', { position: "top-right", autoClose: 1000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", });
            handleShowLoginModel();
            return;
        }
        const itemIndex = cartItems.findIndex(cartItem => cartItem._id === item._id);
        let updatedCartItems;
        toast.success('Dish add to cart', { position: "top-right", autoClose: 500, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", });
        if (itemIndex !== -1) {
            // Item exists in cart, increase quantity
            updatedCartItems = cartItems.map((cartItem, index) =>
                index === itemIndex ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
            );
        } else {
            updatedCartItems = [...cartItems, { ...item, quantity: 1 }];
        }
        setCartItems(updatedCartItems);
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    };

    // Send cart items from sessionStorage
    useEffect(() => {
        const storedCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        setCartItems(storedCartItems);
    }, []);

    // get user email or role to session storage
    const fetchUserData = () => {
        const userData = JSON.parse(sessionStorage.getItem('user'));
        if (userData && userData.email && userData.role) {
            setUser(userData);
        } else {
            setUser(null);
        }
    };
    // Login Model
    const handleShowLoginModel = () => {
        setShowSignupModal(false);
        setShowLoginModel(true);
    };
    const handleCloseLoginModel = () => { setShowLoginModel(false); };

    // Signup Model
    const handleShowSignupModel = () => {
        setShowLoginModel(false);
        setShowSignupModal(true);
    };
    const handleCloseSignupModel = () => { setShowSignupModal(false) };
    // Function for handle address model
    const handleShowAddressModal = () => {
        setShowPickupModal(false);
        setShowAddressModal(true);
    };
    const handleCloseAddressModal = () => setShowAddressModal(false);
    // function for handle show pikup model 
    const handleShowPickupModal = () => {
        setShowAddressModal(false);
        setShowPickupModal(true);
    };
    const handleClosePickupModal = () => setShowPickupModal(false);

    // Function to increase quantity
    const increaseQty = (index) => {
        const updatedCartItems = [...cartItems];
        updatedCartItems[index].quantity += 1;
        setCartItems(updatedCartItems);
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    };

    // Function to decrease quantity
    const decreaseQty = (index) => {
        const updatedCartItems = [...cartItems];
        if (updatedCartItems[index].quantity > 1) {
            updatedCartItems[index].quantity -= 1;
        } else {
            toast.info('Dish remove from cart', { position: "top-right", autoClose: 500, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", });
            updatedCartItems.splice(index, 1);
        }
        setCartItems(updatedCartItems);
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    };



    const contextValue = {
        selectedLocation,
        handleSaveSelection,
        sidebarOpen,
        toggleSidebar,
        searchbar,
        handleSearch,
        setCartItems,
        cartItems,
        addToCart,
        setUser, user, fetchUserData,
        // login model
        handleShowLoginModel, showLoginModel, handleCloseLoginModel,
        // signup model
        handleShowSignupModel, showSignupModal, handleCloseSignupModel,
        // address model
        handleShowAddressModal, showAddressModal, handleCloseAddressModal,
        // pickup model
        handleShowPickupModal, showPickupModal, handleClosePickupModal,
        // function to increase and decrease quantity
        increaseQty, decreaseQty,
    };

    return (
        <KababContext.Provider value={contextValue}>
            {children}
        </KababContext.Provider>
    );
};

export const useKababContext = () => React.useContext(KababContext);
