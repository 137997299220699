import React from 'react';
import Header from '../../Components/Header/Header';

function KababRiffamenu() {
    return (
        <div>
            <div>
            <Header />
            </div>
            <div>
            <h2 className='main-div-iframepdfshow'>KABAB HOUSE RIFFA MENU</h2>
                <iframe
                    src="RiffiaMenu.pdf"
                    className='pdf-menu'
                    title="PDF Viewer"/>
            </div>
        </div>
    )
}

export default KababRiffamenu
