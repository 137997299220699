import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import 'chart.js/auto';
import 'react-datepicker/dist/react-datepicker.css';

const GraphCardComplete = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [orderData, setOrderData] = useState({ labels: [], values: [] });

  // Fetch data for the selected year
  useEffect(() => {
    const fetchYearlyData = async () => {
      const url = `https://backend.bkabab.com/completed/revenue/monthly?year=${selectedYear}`;
      const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  
      try {
        const response = await fetch(url);
        const data = await response.json();
        console.log(data);  // Add this line to check the data
  
        const formattedData = {
          labels: labels,
          values: labels.map((label, index) => {
            const item = data.find(d => d.month === (index + 1)); // Match by month
            return item ? item.totalRevenue : 0; // Set revenue or 0 if not found
          }),
        };
  
        setOrderData(formattedData);
      } catch (error) {
        console.error('Error fetching order data:', error);
      }
    };
  
    fetchYearlyData();
  
    const intervalId = setInterval(() => {
      fetchYearlyData();
    }, 1000);
  
    return () => clearInterval(intervalId);
  }, [selectedYear]);
  

  const chartData = {
    labels: orderData.labels,
    datasets: [
      {
        label: 'Total Revenue',
        data: orderData.values,
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: '#333',
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.7)',
        titleColor: '#fff',
        bodyColor: '#fff',
        footerColor: '#fff',
      },
    },
    animation: {
      duration: 1500,
      easing: 'easeOutBounce',
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: '#333',
          callback: function (value) {
            if (value >= 1000000000) {
              return (value / 1000000000).toFixed(1) + 'B';
            } else if (value >= 1000000) {
              return (value / 1000000).toFixed(1) + 'M';
            } else if (value >= 1000) {
              return (value / 1000).toFixed(1) + 'k';
            }
            return value;
          },
        },
        grid: {
          color: 'rgba(54, 162, 235, 0.2)',
        },
      },
      x: {
        ticks: {
          color: '#333',
        },
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className='main-graph-container-adminpenal-dashbord'>
      <div>
        <div className='graph-header-dashbord'>
          <h2 className='graph-tital-dashbord'>Completed Order Revenue</h2>
          <div className='graph-date-picker'>
            <DatePicker
              selected={new Date(`${selectedYear}-01-01`)}
              onChange={(date) => setSelectedYear(date.getFullYear())}
              showYearPicker
              dateFormat="yyyy"
              className='graph-btn-dashbord-slector'
            />
          </div>
        </div>
        <div className='main-chart-container-graph-dashbor'>
          {orderData.labels.length > 0 ? <Bar data={chartData} options={options} /> : <p>No data available</p>}
        </div>
      </div>
    </div>
  );
};

export default GraphCardComplete;
