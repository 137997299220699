import React, { useState } from 'react';
import { Modal, Form, Button, Spinner } from 'react-bootstrap'; // Spinner add kiya
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

function SignUpModel({ show, handleClose, handleShowLoginModel }) {
  const [handle, setHandle] = useState({
    name: '',
    email: '',
    phoneno: '',
    password: '',
    dateofbirth: ''
  });
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [showPassword, setShowPassword] = useState(false);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setHandle({ ...handle, [name]: value });
  };

  const handleFormData = async (e) => {
    e.preventDefault();
    setValidated(true);

    if (!handle.name || !handle.email || !handle.phoneno || !handle.password || !handle.dateofbirth) {
      toast.error('All fields are required.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
      return;
    }

    if (handle.phoneno.length !== 11) {
      toast.error('Phone number must be exactly 11 digits.', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
      return;
    }

    setIsLoading(true); // Loading state ko true set kar diya

    try {
      const user = {
        name: handle.name,
        email: handle.email,
        phoneno: handle.phoneno,
        password: handle.password,
        dateofbirth: handle.dateofbirth,
      };

      const response = await fetch('https://backend.bkabab.com/adduser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      if (!response.ok) {
        if (response.status === 400) {
          const errorText = await response.text();
          if (errorText === 'Email already exists') {
            toast.error('Email already exists', {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light"
            });
            return;
          }
        }
        throw new Error(`Failed to create User: ${response.statusText}`);
      }

      setHandle({
        name: '',
        email: '',
        phoneno: '',
        password: '',
        dateofbirth: ''
      });
      handleClose();
      toast.success('Registered successfully', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    } catch (error) {
      console.error('Error creating user:', error.message);
      toast.error('Error creating user: ' + error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      });
    } finally {
      setIsLoading(false); // Loading state ko false set kar diya
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
      <Modal.Header>
        <Modal.Title className='model-main'>
          <img src='BkababLogo.jpg' width={70} alt='Bahrain Kebabs Logo' />
          <p className='mode-tital'>Signup in Kabab House</p>
        </Modal.Title>
        <Link variant="secondary" className='close-link-model' onClick={handleClose}>
          X
        </Link>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleFormData}>
          <Form.Group controlId="inputName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder='Name *'
              name='name'
              value={handle.name}
              onChange={handleInput}
              required
            />
          </Form.Group>

          <Form.Group controlId="inputEmail" className="mt-1">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder='E-mail *'
              name='email'
              value={handle.email}
              onChange={handleInput}
              required
            />
          </Form.Group>

          <Form.Group controlId="inputnumber" className="mt-2">
            <Form.Label>Phone No. / Date of Birth</Form.Label>
            <div className='input-l-f-name'>
              <div className='input-phoneno-model-signup'>
                <Form.Control
                  type="number"
                  placeholder='Phone No. *'
                  name='phoneno'
                  value={handle.phoneno}
                  onChange={handleInput}
                  required
                />
              </div>
              <Form.Control
                type="date"
                name='dateofbirth'
                value={handle.dateofbirth}
                onChange={handleInput}
                required
              />
            </div>
          </Form.Group>

          <Form.Group controlId="inputPassword" className="mt-2">
            <Form.Label>Password</Form.Label>
            <div className='password-input-container'>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder='Password *'
                name='password'
                value={handle.password}
                onChange={handleInput}
                required
              />
              <span className='password-toggle-icon' onClick={togglePasswordVisibility}>
                {showPassword ? '👁️' : '🙈'}
              </span>
            </div>
          </Form.Group>

          <div className='login-btn-main-div-model mt-3'>
            <Button type="submit" className='login-model-main' disabled={isLoading}>
              {isLoading ? <Spinner animation="border" size="sm" /> : 'Sign Up'}
            </Button>
            <div className='mt-3'>
              <p className='text-for-register-model-login'>
                You have an account? <Link onClick={handleShowLoginModel} className='register-login-model-link'>Login now!</Link>
              </p>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className='models-footer-main'>
          <div className='copy-right-text-bottom-footer'>
            <p>Copyright © 2024, All Right Reserved. Powered By </p> &nbsp; &nbsp;<Link className='bottom-footer-links'> SGB WORLD </Link>&nbsp;&nbsp;
          </div>
        </div>
      </Modal.Footer>
    </Modal >
  );
}

export default SignUpModel;
