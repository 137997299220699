import React, { useEffect, useState } from 'react';
import { Table, Col, Row, Button } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { Link, useNavigate } from 'react-router-dom';
import { useKababContext } from '../../../../../KababContext';
import moment from 'moment';
import ReceiptModal from './ReceiptModal ';

const OrderList = () => {
    const { searchbar } = useKababContext();
    const [data, setData] = useState([]);
    const [showReceiptModal, setShowReceiptModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const navigate = useNavigate();

    const fetchApi = async () => {
        try {
            const response = await fetch("https://backend.bkabab.com/orders");
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            const value = await response.json();
            value.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setData(value);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchApi();
        const intervalId = setInterval(() => { 
            fetchApi();
        }, 500); 
        return () => clearInterval(intervalId);
    }, []);

    const pendingOrders = data.filter(order => order.status === 'pending');

    const filteredOrders = pendingOrders.filter(order => {
        const name = order.name?.toLowerCase() || "";
        const category = order.category?.toLowerCase() || "";
        return (name + ' ' + category).includes(searchbar.toLowerCase());
    });

    const handleStatusChange = async (orderId, newStatus) => {
        try {
            const response = await fetch(`https://backend.bkabab.com/orders/${orderId}/status`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus }),
            });
            if (!response.ok) {
                throw new Error('Failed to update order status');
            }
            fetchApi();
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    };

    const formatTime = (createdAt) => {
        const time = moment(createdAt);
        const now = moment();
        const diffInHours = now.diff(time, 'hours');

        return diffInHours < 24 ? time.format('HH:mm A') : time.fromNow();
    };

    const handleConfirmClick = async (order) => {
        await handleStatusChange(order._id, 'completed');
        setSelectedOrder(order);
        setShowReceiptModal(true);
    };

    const handlePrint = () => {
        window.print();
    };

    return (
        <div>
            <div className='table-order-list'>
                <Table bordered>
                    <thead>
                        <tr className='tr-order-history-table'>
                            <th>Customer</th>
                            <th>Time</th>
                            <th>Status</th>
                            <th>Total Amount</th>
                            <th>Payment Method</th>
                            <th className='order-name-qty-th'>Order</th>
                            <th>Special Instructions</th>
                            <th>Customer Info</th>
                            <th className='main-th-action'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOrders.map((order) => (
                            <tr key={order._id}>
                                <td>{order.name}<br /><br /> {order.phoneno}</td>
                                <td>{formatTime(order.createdAt)}</td>
                                <td>
                                    <p className={`order-status ${order.status?.toLowerCase()}`}>
                                        {order.status}
                                    </p>
                                </td>
                                <td>{order.totalAmount} $</td>
                                <td>{order.paymentMethod}</td>
                                <td>
                                    {order.items.map(item => (
                                        <div className='order-detail-admin-sight' key={item._id}>
                                          <span className='dish-name-order-adminsight'>{item.dishname}</span> <span className='dish-qty-order-adminsight'>{item.quantity}</span>
                                        </div>
                                    ))}
                                </td>
                                <td>{order.specialInstruction}</td>
                                <td>
                                    <p>{order.location}</p>
                                </td>
                                <td className='action-btns-order-list'>
                                    <div className='action-btn-mobile-screen-order-list'>
                                        <Link
                                            className='order-action-links-crose'
                                            data-tooltip-id="cancelTooltip"
                                            data-tooltip-content="Cancel"
                                            onClick={() => handleStatusChange(order._id, 'canceled')}
                                        >
                                            X
                                        </Link>
                                        <Link
                                            className='order-action-links-tick'
                                            data-tooltip-id="confirmTooltip"
                                            data-tooltip-content="Confirmed"
                                            onClick={() => handleConfirmClick(order)}
                                        >
                                            <i className="las la-check"></i>
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Tooltip id="cancelTooltip" />
                <Tooltip id="confirmTooltip" />
            </div>

            {/* Mobile-friendly view */}
            <div className='mobile-view'>
                <Row>
                    {filteredOrders.map((order, index) => (
                        <Col md={6} key={index} className='order-column'>
                            <div className='mobile-order-card'>
                                <div className='order-name-time-mobile'>
                                    <div>
                                        <h5>{order.name}</h5>
                                        <p className=''>{order.phoneno}</p>
                                        <p className='order-time'>{formatTime(order.createdAt)}</p>
                                    </div>
                                    <div>
                                        <p className={`order-status ${order.status?.toLowerCase()}`}>
                                            {order.status}
                                        </p>
                                    </div>
                                </div>
                                <hr />
                                <div className='product-details'>
                                    {order.items.map(item => (
                                        <div key={item._id}>
                                            {item.dishname} (x{item.quantity})
                                        </div>
                                    ))}
                                </div>
                                <hr />
                                <div className='order-monile-pickupdelevery-addressbranch'>
                                    <div>
                                        <p className='order-address-branch-mobile'>{order.location}</p>
                                        <p className='order-address-branch-mobile'>{order.specialInstruction}</p>
                                    </div>
                                </div>
                                <div className='amount-btns-order-mobile'>
                                    <div>
                                        <p>{order.totalAmount} $</p>
                                    </div>
                                    <div className='action-btns-order-list'>
                                        <div className='action-btn-mobile-screen-order-list'>
                                            <Link
                                                className='order-action-links-crose'
                                                data-tooltip-id="cancelTooltip"
                                                data-tooltip-content="Cancel"
                                                onClick={() => handleStatusChange(order._id, 'canceled')}
                                            >
                                                X
                                            </Link>
                                            <Link
                                                className='order-action-links-tick'
                                                data-tooltip-id="confirmTooltip"
                                                data-tooltip-content="Confirmed"
                                                onClick={() => handleConfirmClick(order)}
                                            >
                                                <i className="las la-check"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>

            {/* Receipt Modal */}
            {selectedOrder && (
                <ReceiptModal show={showReceiptModal} onHide={() => setShowReceiptModal(false)} order={selectedOrder} handlePrint={handlePrint} />
            )}
        </div>
    );
};

export default OrderList;
