import React from 'react'
import { useKababContext } from '../../../../KababContext';
import { Row, Col, Container } from 'react-bootstrap';
import OrderCard1 from './OrderCards/OrderCard1/OrderCard1';
import OrderCard2 from './OrderCards/OrderCard2/OrderCard2';
import OrderCard3 from './OrderCards/OrderCard3/OrderCard3';
import OrderCard4 from './OrderCards/OrderCard4/OrderCard4';
import OrderSearchbar from './OrderSearchBar/OrderSearchbar';
import OrderList from './OrderList/OrderList';

function OrderContent() {
  const { sidebarOpen } = useKababContext();
  const contentStyle = sidebarOpen ? 'Side-bar-closed' : 'Side-bar-open';
  return (
    <div className={contentStyle}>
      <p className='main-tital-adminpenal'>
        Orders
      </p>
      <div className='main-order-conmponents'>
        <Container fluid className='container-searchbarone'>
          <OrderSearchbar/>
        </Container>
        <Container fluid>
          <Row className='main-row-order-cards'>
            <Col xs={12} sm={6} xl={3}><OrderCard1 /></Col>
            <Col xs={12} sm={6} xl={3}><OrderCard2 /></Col>
            <Col xs={12} sm={6} xl={3}><OrderCard3 /></Col>
            <Col xs={12} sm={6} xl={3}><OrderCard4 /></Col>
          </Row>
        </Container>
        <Container fluid className='container-searchbarsecond'>
          <OrderSearchbar/>
        </Container>
        <Container fluid >
          <OrderList/>
        </Container>
      </div>
    </div>
  )
}

export default OrderContent
